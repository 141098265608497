import React from 'react';
import { UnseenMessageProvider } from './common/context/UnseenMessageContext';
//imoprt Routecommon
import Route from './Routes';
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <React.Fragment>
      <UnseenMessageProvider>
      <Route />
      <ToastContainer/>
      </UnseenMessageProvider>
    </React.Fragment>
  );
}

export default App;
