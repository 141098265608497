import React, { useEffect, useRef, useState } from "react";
import NoImage from "../../../../assets/images/NoImage copy.jpg";
import ScrollToTop from "../../../../common/ScrollToTop/ScrollToTop";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import SimpleBar from "simplebar-react";
const TicketChatHistory = ({
    openModel,
    handleCloseModel,
    ticketChat,
    formatDateString,
}) => {
    return (
        <Modal
            isOpen={openModel}
            toggle={() => {
                handleCloseModel();
            }}
            backdrop={"static"}
            id="staticBackdrop"
            size="lg"
            centered
        >
        <SimpleBar style={{ maxHeight: "calc(100vh - 100px)", overflowX: "auto", }}>
            <ModalHeader className="p-3 chat-modal">
                <div>
                    <div><span className="icon">
                        <i className="ri-history-line d-inline-block me-2"></i>
                    </span>Chat History</div>
                    
                </div>
                <button
                    type="button"
                    className="btn-close"
                    onClick={handleCloseModel}
                    aria-label="Close"
                ></button>
            </ModalHeader>
            <SimpleBar style={{ maxHeight: "calc(100vh - 50px)", overflowX: "auto", }}>
            <ModalBody className="border border-dashed border-start-0 border-end-0">
                <div className="px-3 mx-n3">
                    {ticketChat.map((logs, index) => (
                        <div className="d-flex mb-4">
                            <div className="flex-shrink-0">
                                <img
                                    src={logs?.documentPath || NoImage}
                                    alt=""
                                    className="rounded-circle avatar-xs me-2"
                                />
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h5 className="fs-13">
                                    {logs?.customerName}{" "}
                                    <small className="text-muted">
                                        {formatDateString(logs?.createdDate)}
                                    </small>
                                </h5>
                                <p className="text-muted">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: logs?.message,
                                        }}
                                    />
                                </p>
                                {logs?.replys && logs.replys.map((reply, index) => (
                                    <div className="d-flex mt-3">
                                        <div className="flex-shrink-0">
                                            <img
                                                src={reply?.documentPath || NoImage}
                                                alt=""
                                                className="rounded-circle avatar-xs me-2"
                                            />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h5 className="fs-13">
                                                {reply?.customerName}{" "}
                                                <small className="text-muted">
                                                    {formatDateString(reply?.createdDate)}
                                                </small>
                                            </h5>
                                            <p className="text-muted">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: reply?.message,
                                                    }}
                                                />
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </ModalBody>
            </SimpleBar>
            </SimpleBar>
        </Modal>
    );
};
 
export default TicketChatHistory;