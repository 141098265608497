import React, { useEffect, useState } from "react";
import { BiSortAlt2 } from "react-icons/bi";
import Pagination from "../../CustomComponents/Pagination";
import Loader,{LoaderSpin} from "../../common/Loader/Loader";
import ScrollToTop from "../../common/ScrollToTop/ScrollToTop";
import SimpleBar from "simplebar-react";
import { RefreshCcw } from 'feather-icons-react';
import DepartmentUserInfo from "../../common/UserInfo/DepartmentUserInfo";
import NotFound from "../../common/NotFound/NotFound";
import useAxios from "../../utils/hook/useAxios";

const Support = () => {
    const axiosInstance = useAxios()

    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [orderBy, setOrderBy] = useState();
    const [sortOrder, setSortOrder] = useState("desc");
    //loader
    const [isLoading, setIsLoading] = useState(true);
    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [perPageSize, setPerPageSize] = useState(25);
    const totalPages = Math.ceil(totalCount / perPageSize);

    const fetchSupportList = async () => {
        try {
            setIsLoading(true);
            const response = await axiosInstance.post(
                `userService/support/view`,
                {
                    page: currentPage,
                    perPage: perPageSize,
                    sortOrder: sortOrder,
                    orderBy: orderBy,
                }
            );

            if (response?.data) {
                const { rows, count } = response?.data?.data;

                setData(rows);
                setTotalCount(count);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error.message);
        }
    };

    const listOfSearch = async () => {
        try {
            setIsLoading(true);
            const response = await axiosInstance.post(
                `userService/support/view`,
                {
                    page: currentPage,
                    perPage: perPageSize,
                    searchFilter: searchQuery,
                    sortOrder: sortOrder,
                    orderBy: orderBy,
                }
            );

            if (response?.data) {
                const { rows, count } = response?.data?.data;

                setData(rows);
                setTotalCount(count);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error.message);
        }
    };

    useEffect(() => {
        const delayedSearch = setTimeout(() => {
            if (searchQuery) {
                listOfSearch();
            }
        }, 500);
        return () => clearTimeout(delayedSearch);
    }, [searchQuery, currentPage, perPageSize, orderBy, sortOrder]);

    useEffect(() => {
        if (!searchQuery) {
            fetchSupportList();
        }
    }, [searchQuery, currentPage, perPageSize, orderBy, sortOrder]);

    const handleSelectPageSize = (e) => {
        setCurrentPage(1);
        setPerPageSize(parseInt(e.target.value, 10));
    };

    const handleInputSearch = (e) => {
        setCurrentPage(1);
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (page) => {
        if (page < 1) {
            page = 1;
        } else if (page > totalPages) {
            page = totalPages;
        }
        setCurrentPage(page);

        if (page === totalPages) {
            document
                .querySelector(".pagination-next")
                .classList.add("disabled");
        } else {
            document
                .querySelector(".pagination-next")
                .classList.remove("disabled");
        }

        if (page === 1) {
            document
                .querySelector(".pagination-prev")
                .classList.add("disabled");
        } else {
            document
                .querySelector(".pagination-prev")
                .classList.remove("disabled");
        }
    };

    const resetFilters = async () => {
        setCurrentPage(1);
        setPerPageSize(25);
        setSearchQuery("");
    };

    const handleSorting = (value) => {
        setOrderBy(value);
        setSortOrder((prevSortOrder) =>
            prevSortOrder === "asc" ? "desc" : "asc"
        );
    };

    document.title = "Support | eGov Solution"

    return (
        <div>
           
                <div id="layout-wrapper">
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row">
                                    <DepartmentUserInfo />
                                    <div className="col-12">
                                        <div className="page-title-box header-title d-sm-flex align-items-center justify-content-between pt-lg-4 pt-3">
                                            <h4 className="mb-sm-0">
                                                Support Messages
                                            </h4>
                                            <div className="page-title-right">
                                                <div className="mb-0 me-2 fs-15 text-muted current-date"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xxl-12 mb-3">
                                    <div className="card border-0">
                                        <div className="card-body border-0">
                                            <div className="row">
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xxl-2 mb-3 mb-md-0">
                                                    <div className="search-box">
                                                        <input type="text" className="form-control search bg-light border-light" placeholder="Search" value={searchQuery} onChange={(e) => handleInputSearch(e)} />
                                                        <i className="ri-search-line search-icon"></i>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-lg-4 col-9 col-md-4 col-sm-6  col-xxl-4 ms-auto text-end">
                                                    <button type="button" className="btn btn-primary bg-light border-light text-muted " onClick={resetFilters} >
                                                        <RefreshCcw className="text-muted me-2" width="16" height="16" />
                                                        <span> Reset </span>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-12">
                                    <div className="card border-0 mb-0">
                                        <div className="card-body pb-0">
                                            <div className="table-responsive table-card mb-0">
                                                <SimpleBar style={{ maxHeight: 'calc(100vh - 50px)', overflowX: 'auto' }}>
                                                    <table
                                                        className="table align-middle table-nowrap mb-0 com_table"
                                                        id="tasksTable"
                                                    >
                                                        <thead className="sticky-top bg-white">
                                                            <tr>
                                                                <th className="fw-bold cursor-pointer" onClick={() => handleSorting("name")} >
                                                                    Name{" "} <span> <BiSortAlt2 /> </span>
                                                                </th>
                                                                <th className="fw-bold cursor-pointer" onClick={() => handleSorting("email")} >
                                                                    Email{" "} <span> <BiSortAlt2 /> </span>{" "}
                                                                </th>
                                                                <th className="fw-bold cursor-pointer" onClick={() => handleSorting("departmentId")} >
                                                                    Department{" "} <span> <BiSortAlt2 /> </span>
                                                                </th>
                                                                <th className="fw-bold cursor-pointer" onClick={() => handleSorting("message")} >
                                                                    Message{" "} <span> <BiSortAlt2 /> </span>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                       
                                                              <tbody>
                                                                {isLoading ? (
                                                                    <tr>
                                                                    <td colSpan="6" className="text-center">
                                                                        <LoaderSpin /> 
                                                                    </td>
                                                                </tr>
                                                                ): data.length ===0 ?(
                                                                    <tr>
                                                                        <td colSpan="6" className="text-center" >
                                                                            <NotFound heading="Support messages not found." message="Unfortunately, support messages not available at the moment." />
                                                                        </td>
                                                                    </tr>
                                                                ):(
                                                                    data?.map((support, index) => (
                                                          
                                                                        <tr key={index}>
                                                                            <td>
                                                                                <div className="fw-bold text-black">
                                                                                    {support.name}
                                                                                </div>
                                                                            </td>
        
                                                                            <td>
                                                                                {support.email}
                                                                            </td>
        
                                                                            <td>
                                                                                {support.departmentData.departmentName}
                                                                            </td>
                                                                            <td  >
                                                                                {support.message}
                                                                            </td>
                                                                        </tr>
                                                                   
                                                                )
                                                                )
                                                                )}
                                                       
                                                         </tbody>
                                                    </table>
                                                </SimpleBar>
                                            </div>
                                        </div>
                                        <Pagination
                                            totalCount={totalCount}
                                            perPageSize={perPageSize}
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            handleSelectPageSize={handleSelectPageSize}
                                            handlePageChange={handlePageChange}
                                        />
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
 
            <ScrollToTop />
        </div>
    );
};

export default Support;
