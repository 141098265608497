import React from "react";
import { Button, Modal } from "react-bootstrap";

const TransactionStatusModal = ({ show, setShow, handleToggle,transactionDetails }) => {
  function getMonthName(date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[date.getMonth()];
  }
  function formatDate(dateString) {
    // Parse the input date string
    const date = new Date(dateString);

    // Format the date in the desired format (08 Mar, 2024)
    const formattedDate = `${("0" + date.getDate()).slice(-2)} ${getMonthName(
      date
    )}, ${date.getFullYear()}`;

    // Format the time in the desired format (01:00 PM)
    const formattedTime = `${("0" + date.getHours()).slice(-2)}:${(
      "0" + date.getMinutes()
    ).slice(-2)} ${date.getHours() >= 12 ? "PM" : "AM"}`;

    return (
      <div>
        <div className="four">{formattedDate}</div>
        <br />
        <small className="text-muted">{formattedTime}</small>
      </div>
    );
  }
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={handleToggle}
      centered
    >
      <div className="modal-body">
        <div className="card mb-0">
          <div className="card-header badge-soft-success">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h5 className="card-title mb-0">
                  <i className="ri-secure-payment-line align-bottom me-2 text-muted"></i>
                  Payment Details
                </h5>
              </div>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleToggle} ></button>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive table-card">
              <table className="table table-borderless align-middle mb-0">
                <tbody>
                  <tr>
                    <td className="text-muted fs-13" colSpan="2">
                      Payment Mode :
                    </td>
                    <td className="fw-semibold text-end">
                      Credit Card ( VISA )
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="text-muted fs-13">
                      Transaction Number :
                    </td>
                    <td className="fw-semibold text-end">{transactionDetails?.transaction?.transactionId}</td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="text-muted fs-13">
                      Citizen Receipt Number :{" "}
                    </td>
                    <td className="fw-semibold text-end">{transactionDetails?.transaction?.transactionReceipt}</td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="text-muted fs-13">
                      Transaction Date Time :{" "}
                    </td>
                    <td className="fw-semibold text-end">
                     {transactionDetails?.transaction?.createdDate && formatDate(transactionDetails?.transaction?.createdDate)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="text-muted fs-13">
                      Transaction Status :{" "}
                    </td>
                    <td className="fw-semibold text-end">{transactionDetails?.transaction?.transactionStatus}</td>
                  </tr>
                  <tr className="table-active">
                    <th colSpan="2">Total :</th>
                    <td className="text-end">
                      <div className="fw-semibold">${transactionDetails?.transaction?.transactionAmount}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TransactionStatusModal;
