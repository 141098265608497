import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
} from "reactstrap";
import Select from "react-select";
import { useState } from "react";
import { useEffect } from "react";
import { BiSortAlt2 } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { decrypt } from "../../../../utils/encryptDecrypt/encryptDecrypt";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import errorImage from "../../../../assets/images/error.gif";
import {
  hasCreatePermission,
  hasDeletePermission,
  hasEditPermission,
  hasViewPermission,
} from "../../../../common/CommonFunctions/common";
import Pagination from "../../../../CustomComponents/Pagination";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Loader,{LoaderSpin} from "../../../../common/Loader/Loader";
import ScrollToTop from "../../../../common/ScrollToTop/ScrollToTop";
import SimpleBar from "simplebar-react";
import { RefreshCcw } from "feather-icons-react";
import DepartmentUserInfo from "../../../../common/UserInfo/DepartmentUserInfo";
import { Eye } from "feather-icons-react/build/IconComponents";
import NotFound from "../../../../common/NotFound/NotFound";
import useAxios from "../../../../utils/hook/useAxios";
const BlankData = process.env.REACT_APP_BLANK;
const WorkFlow = () => {
  const axiosInstance = useAxios()
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const userEncryptData = localStorage.getItem("userData");
  const userDecryptData = userEncryptData
    ? decrypt({ data: userEncryptData })
    : {};
  const userData = userDecryptData?.data;
  const userId = userData?.id;
  const [searchQuery, setSearchQuery] = useState("");
  const [orderBy, setOrderBy] = useState();
  const [sortOrder, setSortOrder] = useState("desc");
  const [selectedDept, setSelectedDept] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [perPageSize, setPerPageSize] = useState(25);
  const totalPages = Math.ceil(totalCount / perPageSize);
  // upload Image
  const [selectedFile, setSelectedFile] = useState(null);
  const [isWorkflowLoading, setiIsWorkflowLoading] = useState(true);

  const handleSorting = (value) => {
    setOrderBy(value);
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  };
  const userPermissionsEncryptData = localStorage.getItem("userPermissions");
  const userPermissionsDecryptData = userPermissionsEncryptData
    ? decrypt({ data: userPermissionsEncryptData })
    : { data: [] };
  const EventPermissions =
    userPermissionsDecryptData &&
    userPermissionsDecryptData?.data?.find(
      (module) => module.slug === "workflow"
    );
  const viewPermissions = EventPermissions
    ? hasViewPermission(EventPermissions)
    : false;
  const createPermission = EventPermissions
    ? hasCreatePermission(EventPermissions)
    : false;
  const editPermission = EventPermissions
    ? hasEditPermission(EventPermissions)
    : false;
  const deletePermission = EventPermissions
    ? hasDeletePermission(EventPermissions)
    : false;

  const fetchWorkflowList = async () => {
    try {
      setiIsWorkflowLoading(true);
      const response = await axiosInstance.post(`userService/workflow/list`, {
        page: currentPage,
        perPage: perPageSize,
        searchFilter: searchQuery,
        workflowDepartmentId:
          userData?.isCoreTeam === "0" ? userData?.departmentId : selectedDept,
        sortOrder: sortOrder,
        orderBy: orderBy,
      });

      // Decrypt the response data if needed
      if (response?.data) {
        const { rows, count } = response?.data?.data;
        setTotalCount(count);
        setData(rows);
        setiIsWorkflowLoading(false);
      }
    } catch (error) {
      setiIsWorkflowLoading(false);
      console.error(error.message);
    }
  };

  const listOfDepartments = async () => {
    try {
      const response = await axiosInstance.post(
        `serviceManagement/department/view`,
        {}
      );
      if (response?.data) {
        const { rows } = response?.data?.data;
        setDepartmentList(rows);
      }
    } catch (error) {
      console.error("No results found for the given search query.");
    }
  };
  useEffect(() => {
    listOfDepartments();
  }, []);

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      if (searchQuery) {
        fetchWorkflowList();
      }
    }, 500);
    return () => clearTimeout(delayedSearch);
  }, [currentPage, perPageSize, searchQuery, sortOrder, orderBy, selectedDept]);

  useEffect(() => {
    if (!searchQuery) {
      fetchWorkflowList();
    }
  }, [currentPage, perPageSize, searchQuery, sortOrder, orderBy, selectedDept]);

  const handleDepartmentSearch = (value) => {
    setCurrentPage(1);
    if (value) {
      setSelectedDept(value);
    }else{
      setSelectedDept("")
    }
  };
  const handleSelectPageSize = (e) => {
    setCurrentPage(1);
    setPerPageSize(parseInt(e.target.value, 10));
  };
  const handleInputSearch = (e) => {
    setCurrentPage(1);
    setSearchQuery(e.target.value);
  };
  const resetFilters = async () => {
    setCurrentPage(1);
    setSelectedDept("");
    setPerPageSize(25);
    setSearchQuery("");
    setOrderBy();
    setSortOrder("desc");
  };
  const deleteWorkflow = async (workflowId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this workflow!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#303e4b",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await axiosInstance.put(
          `userService/workflow/delete`,
          {
            workflowId: workflowId,
          }
        );
        if (response) {
          toast.success(`Workflow deleted successfully.`);
          fetchWorkflowList();
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(`Failed to delete Workflow.`);
        console.error(error);
      }
    }
  };
  const getWorkflowApi = async (workflowId) => {
    navigate("/add-workflow", {
      state: workflowId,
    });
  };

  const handlePageChange = (page) => {
    if (page < 1) {
      page = 1;
    } else if (page > totalPages) {
      page = totalPages;
    }
    setCurrentPage(page);

    if (page === totalPages) {
      document.querySelector(".pagination-next").classList.add("disabled");
    } else {
      document.querySelector(".pagination-next").classList.remove("disabled");
    }

    if (page === 1) {
      document.querySelector(".pagination-prev").classList.add("disabled");
    } else {
      document.querySelector(".pagination-prev").classList.remove("disabled");
    }
  };

  const departmentOptions = departmentList.length > 0 && 
  [{ value: "", label: "Select Department*" }, ...departmentList.map((deparment) => ({
    value: deparment.id,
    label: deparment.departmentName,
  }))]

  document.title = "Workflow | eGov Solution";
  return (
    <>
     
        <div id="layout-wrapper">
          <div className="main-content services">
            <div className="page-content">
              <Container fluid>
                <Row>
                  <DepartmentUserInfo />
                  <Col className="col-12">
                    <div className="d-flex align-items-center ">
                      <div className="flex-grow-1">
                        <div className="d-flex align-items-center">
                          <div className="page-title-box header-title pt-lg-4 pt-3">
                            <h4 className="mb-sm-0">Workflow</h4>
                            {/* <div className="">
                              <div className="fs-15 mt-1 text-muted mb-0">
                                All systems are running smoothly! You have{" "}
                                <span className="text-primary">
                                  {" "} 2 unread alerts!{" "}
                                </span>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>

                    </div>
                  </Col>
                  <Col className="col-12 ">
                    <Card className="border-0">
                      <CardBody className="border-0">
                        <div className="row">
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xxl-3 mb-3 mb-md-0">
                            <div className="search-box">
                              <Input
                                type="text"
                                className="form-control search bg-light border-light"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={handleInputSearch}
                              />
                              <i className="ri-search-line search-icon"></i>
                            </div>
                          </div>
                          {userData?.isCoreTeam !== "0" && (
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xxl-3 mb-3 mb-md-0">
                              <div className=" input-light">
                                <Select className="bg-choice" options={departmentOptions} onChange={(value) => handleDepartmentSearch(value.value)}
                                  value={selectedDept ? departmentOptions.find((option) => option.value === selectedDept) : null}
                                  placeholder="Select Department*"
                                  name="Select Department*"
                                  styles={{
                                    control: (provided) => ({ ...provided, cursor: "pointer", }),
                                    menu: (provided) => ({ ...provided, cursor: "pointer", }),
                                    option: (provided) => ({ ...provided, cursor: "pointer", }),
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          <div className="col-xl-3 col-lg-3 col-md-3 col-6 col-xxl-3">
                            <Button type="button" className="btn btn-primary bg-light border-light text-muted d-flex align-items-center" onClick={resetFilters} >
                              <RefreshCcw className="text-muted me-2" width="16" height="16" />
                              <span>Reset</span>
                            </Button>
                          </div>
                          {createPermission && (
                            <div className="col-xl-3 col-lg-3 col-md-3 col-6 col-xxl-3 ms-auto text-end">
                              <Button type="button" color="primary" className="btn btn-primary " id="create-btn" onClick={() => navigate("/add-workflow")} >
                                {" "} Add Workflow{" "}
                              </Button>
                            </div>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="col-12">
                    <Card className="border-0 mb-0">
                      <CardBody className="pb-0">
                        <div className="table-responsive table-card mb-0">
                          <SimpleBar
                            style={{
                              maxHeight: "calc(100vh - 50px)",
                              overflowX: "auto",
                            }}
                          >
                            <Table
                              className="table align-middle table-nowrap mb-0 com_table"
                              id="tasksTable"
                            >
                              <thead className="sticky-top bg-white">
                                <tr>
                                  <th
                                    className="fw-bold cursor-pointer"
                                    onClick={() =>
                                      handleSorting("workflowName")
                                    }
                                  >
                                    Workflow Name{" "}
                                    <span>
                                      {" "}
                                      <BiSortAlt2 />{" "}
                                    </span>
                                  </th>
                                  <th
                                    className="fw-bold cursor-pointer"
                                    onClick={() => handleSorting("workflowFor")}
                                  >
                                    Workflow For{" "}
                                    <span>
                                      {" "}
                                      <BiSortAlt2 />{" "}
                                    </span>
                                  </th>
                                  <th
                                    className="fw-bold cursor-pointer"
                                    onClick={() =>
                                      handleSorting("workflowDepartmentId")
                                    }
                                  >
                                    Department Name{" "}
                                    <span>
                                      {" "}
                                      <BiSortAlt2 />{" "}
                                    </span>
                                  </th>

                                  <th
                                    className="fw-bold cursor-pointer"
                                    onClick={() => handleSorting("userId")}
                                  >
                                    User Name{" "}
                                    <span>
                                      {" "}
                                      <BiSortAlt2 />{" "}
                                    </span>
                                  </th>
                                  <th className="fw-bold text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody >
                              {isWorkflowLoading?(<tr>
                               <td colSpan="6" className="text-center">
                               <LoaderSpin /> 
                               </td>
                           </tr>):data.length === 0?(
                             <tr>
                             <td colSpan="6" className="text-center">
                               {" "}
                               <NotFound heading="Workflow not found." message="Unfortunately, workflow not available at the moment." />
                               {" "}
                             </td>
                           </tr>
                           ):(
                            data.map((workflow, index) => (
                            
                                <tr key={index}>
                                  <td className="text-nowrap">
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <div className="fw-semibold text-black">
                                          {workflow?.workflowName || BlankData}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="text-nowrap">
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <div className="fw-semibold text-black">
                                          {workflow?.workflowFor === "0"
                                            ? "Service"
                                            : workflow?.workflowFor === "1"
                                              ? "Ticket"
                                              : ""}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="text-nowrap">
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <div className="fw-semibold text-black">
                                          {
                                            workflow?.department
                                              ?.departmentName
                                               || BlankData
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="text-nowrap">
                                    <div className="d-flex align-items-center">
                                      <div className="fw-semibold text-black">
                                        {workflow?.user?.name  || BlankData}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="w-md text-end">
                                    <span>
                                      {viewPermissions &&
                                        !editPermission && (
                                          <span
                                            title="view"
                                            onClick={() =>
                                              getWorkflowApi(workflow.id)
                                            }
                                          >
                                            <Eye
                                              width="16"
                                              height="16"
                                              className="text-primary me-4"
                                            />
                                          </span>
                                        )}
                                      {editPermission && (
                                        <span
                                          title="Edit"
                                          onClick={() =>
                                            getWorkflowApi(workflow.id)
                                          }
                                        >
                                          <FiEdit2 className=" cursor-pointer me-4" />
                                        </span>
                                      )}
                                      {deletePermission && (
                                        <span
                                          title="Delete"
                                          onClick={() =>
                                            deleteWorkflow(workflow.id)
                                          }
                                        >
                                          <RiDeleteBinLine className="cursor-pointer" />
                                        </span>
                                      )}
                                    </span>
                                  </td>
                                </tr>
                           
                            ))
                           )}
                            
                            </tbody>
                            </Table>
                          </SimpleBar>
                        </div>
                      </CardBody>
                      <Pagination
                        totalCount={totalCount}
                        perPageSize={perPageSize}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        handleSelectPageSize={handleSelectPageSize}
                        handlePageChange={handlePageChange}
                      />
                    </Card>
                  </Col>
                </Row>
              
              </Container>
            </div>
          </div>
        </div>
    
      <ScrollToTop />
    </>
  );
};
export default WorkFlow;
