import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logoDark from "../../assets/images/logo-dark-d.png";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { decrypt } from "../../utils/encryptDecrypt/encryptDecrypt";
import { Button, Spinner } from "react-bootstrap";
import { RiLockPasswordLine } from "react-icons/ri";
import useAxios from "../../utils/hook/useAxios";

function UpdatePassword() {
  const axiosInstance = useAxios()

  const navigate = useNavigate();
  const { userId } = useParams();
  const decryptuserId = decrypt({ data: userId });
  const [confirmPass, setConfirmPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);
  const [errors, setErrors] = useState({
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPassword(!showConfirmPassword);
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Please enter Password"),
    confirmPass: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("Please enter Confirm Password"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      userId: decryptuserId,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await axiosInstance.put(
          `userService/user/create-password`,
          {
            password: values.password,
            confirmPassword: values.confirmPass,
            userId: decryptuserId,
          }
        );

        if (response) {
          setLoading(false);
          toast.success("Password update successfully");
          setErrors({
            password: "",
          });
          setIsResetSuccessful(true);
          navigate("/");
        }
      } catch (error) {
        setLoading(false);
        setIsResetSuccessful(false);
        const { success, data } = error.response;
        setErrors({
          password: data.message,
        });
      }
    },
  });

  document.title = "Update Password | eGov Solution"
  return (
    <>
      <div
        style={{
          background:
            "linear-gradient(130deg, #f99f1e29, #466bb32b 60.07%, #df4f4329 90.05%)",
        }}
      >
        <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
          <div className="auth-page-content overflow-hidden pt-lg-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row g-0">
                    <div className="col-lg-5 m-auto">
                      <div className="card overflow-hidden border-0 mb-0" style={{ boxShadow: "0 0px 15px rgb(0 0 0 / 10%)", borderRadius: "20px", }} >
                        <div className="p-lg-5 p-4">
                          <div className="mb-4 text-center">
                            <img src={logoDark} alt="eGOV.." height="40" />
                          </div>
                          <div className="text-center mb-5">
                            <h4 className="text-primary mb-2">
                              Welcome Back !
                            </h4>
                            <p className="text-muted">
                              Sign in to continue to EGov.
                            </p>
                            {isResetSuccessful && (
                              <div className="text-success text-start d-block" >
                                Password updated successfully!
                              </div>
                            )}
                          </div>
                          <form onSubmit={formik.handleSubmit}>
                            <div className="mt-4">
                              <div className="mb-4">
                                <label className="form-label" htmlFor="password-input" >
                                  New Password{" "}
                                </label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                  <input className="form-control pe-5" placeholder="Enter password" id="password-input"
                                    type={showConfirmPassword ? "text" : "password"}
                                    value={confirmPass}
                                    onChange={(e) => setConfirmPass(e.target.value)}
                                    {...formik.getFieldProps("password")}
                                  />
                                  {formik.touched.password &&
                                    formik.errors.password && (
                                      <div className="text-danger">
                                        {formik.errors.password}
                                      </div>
                                    )}
                                  <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"
                                    onClick={toggleConfirmPasswordVisibility} >
                                    <i className="ri-eye-fill align-middle"></i>
                                  </button>
                                </div>
                              </div>
                              <div className="mb-4">
                                <div className="float-end">
                                  <span onClick={() => navigate("/")} className="text-muted cursor-pointer"  >
                                    Login In?
                                  </span>
                                </div>
                                <label className="form-label" htmlFor="password-input" > Confirm New Password{" "} </label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                  <input type={showPassword ? "text" : "password"} className="form-control pe-5" placeholder="Enter password" id="password-input"
                                    value={formik.values.confirmPass}
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("confirmPass")}
                                  />
                                  {formik.touched.password &&
                                    formik.errors.password && (
                                      <div className="text-danger">
                                        {formik.errors.password}
                                      </div>
                                    )}

                                  <div className="text-danger mt-3" >
                                    {errors.password && (
                                      <>
                                        <div className="text-danger">
                                          <p
                                            style={(formik.values.password ===
                                              formik.values.confirmPass && formik.values.password
                                                .length >= 8 && /[A-Z]/.test(
                                                  formik.values.password
                                                ) && /\d/.test(
                                                  formik.values.password
                                                ) && /\d/.test(formik.values.password) && /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
                                                  formik.values.password)) ? {
                                              color: "green",
                                              marginBottom: 0,
                                            } : {
                                              marginBottom: 0,
                                            }}
                                          >
                                            <RiLockPasswordLine /> Your password must have at least:
                                          </p>
                                          <ul className="ps-0 list-unstyled" >
                                            <li style={{ color: formik.values.password === formik.values.confirmPass ? "green" : "inherit", }} >
                                              {formik.values.password === formik.values.confirmPass ? "✓" : "✗"} Passwords match
                                            </li>
                                            <li style={{ color: formik.values.password.length >= 8 ? "green" : "inherit", }} >
                                              {formik.values.password.length >= 8 ? "✓" : "✗"}{" "} 8 characters long
                                            </li>
                                            <li style={{ color: /[A-Z]/.test(formik.values.password) ? "green" : "inherit", }} >
                                              {/[A-Z]/.test(formik.values.password) ? "✓" : "✗"}{" "} 1 uppercase letter
                                            </li>
                                            <li style={{ color: /\d/.test(formik.values.password) ? "green" : "inherit", }} >
                                              {/\d/.test(formik.values.password) ? "✓" : "✗"}{" "} 1 number
                                            </li>
                                            <li style={{ color: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(formik.values.password) ? "green" : "inherit", }} >
                                              {/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(formik.values.password) ? "✓" : "✗"}{" "} 1 special character
                                            </li>
                                          </ul>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={togglePasswordVisibility} >
                                    <i className="ri-eye-fill align-middle"></i>
                                  </button>
                                </div>
                              </div>
                              <div className="mt-5">
                                <Button className="btn btn-primary w-100" type="submit" disabled={loading} >
                                  {loading ? (
                                    <>
                                      <Spinner animation="border" size="sm" role="status" aria-hidden="true" className="fs-13" />
                                      <span className="fs-13"> {" "} Updating Password... </span>
                                    </>
                                  ) : (
                                    <span className="fs-13"> {" "} Update Password </span>
                                  )}
                                </Button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-5 mx-auto mt-5">
                      <div className="design-by d-flex align-items-center justify-content-center">
                        <span className="text-muted">Crafted by:</span>
                        <span className="netclues" href="#" target="_blank" rel="noopener noreferrer nofollow" title="Netclues!" ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdatePassword;
