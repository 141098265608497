import React from "react";
import ReactApexChart from "react-apexcharts";

const TeamvsTicketChart = ({ data, ticketsViewPermission }) => {
  // Generate random colors
  const predefinedColors = ["#405189", "#f06548", "#f7b84b", "#0ab39c", "#394958"];

  // Function to get colors based on the length of the data
  const generateColorFromPredefinedList = (index) => {
    return predefinedColors[index % predefinedColors.length];
  };

  const series = data?.length > 0 ? data.map(item => item.RequestAssigned) : [1, 1, 1];
  const labels = data?.length > 0 ? data.map(item => item.userName) : ["john", "doe", "test"];
  const colors = data?.length > 0 ? data.map((_, index) => generateColorFromPredefinedList(index)) : predefinedColors;

  const chartOptions = {
    chart: {
      type: "donut",
      width: 500,
    },
    legend: {
      position: "bottom",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,
        donut: {
          labels: {
            show: false,
            total: {
              show: false,
            },
          },
        },
      },
    },
    labels: labels,
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        bottom: -180,
      },
      borderColor: "white",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    annotations: {
      points: [
        {
          x: 50,
          y: 50,
          yAxisIndex: 0,
          seriesIndex: 0,
          marker: {
            size: 8,
            fillColor: "#fff",
            strokeColor: "#0ab39c",
            strokeWidth: 2,
          },
          label: {
            text: "50%",
            borderColor: "#0ab39c",
            offsetY: -10,
          },
        },
      ],
    },
    tooltip: {},
  };

  const blankOptions = {
    chart: {
      type: "donut",
      width: 500,
    },
    colors: ["#cccccc"],
    labels: ["No Data"],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,
        donut: {
          labels: {
            show: false,
            total: {
              show: false,
            },
          },
        },
      },
    },
    legend: {
      position: "bottom",
    },
    tooltip: {},
  };

  if (!ticketsViewPermission) {
    return (
      <div id="chart" className="team-ticket-chart">
      <ReactApexChart
        options={blankOptions}
        series={[1]}
        type="donut"
        style={{ height: 365 }}
      />
      </div>
    );
  }

  return (
    <div id="chart" className="team-ticket-chart">
      <ReactApexChart
        options={chartOptions}
        series={series}
        type="donut"
        style={{ height: 365 }}
      />
    </div>
  );
};

export default TeamvsTicketChart;
