import { useFormik } from "formik";
import React, { useState } from "react";
import {
    Button,
    Form,
    Spinner,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FaRegCopy } from "react-icons/fa";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Select from "react-select";
import { decrypt } from "../../utils/encryptDecrypt/encryptDecrypt";
import infotool from "../../../src/images/svg/icons8-info.svg";
import CKEditorModel from "./ServiceCKEditor";
import PreviewTemplateModel from "./TemplatePreviewModal";
import { Table, InputGroup, Input, Label } from "reactstrap";
import useAxios from "../../utils/hook/useAxios";

const validationSchema = Yup.object({
    serviceName: Yup.string().required("Please enter service name"),
    slug: Yup.string().required("Please enter slug"),
    shortDescription: Yup.string().required("Please enter short description "),
    departmentId: Yup.number().required("Please select department"),
    // currentVersion: Yup.string().required(" Please enter current version"),
    price: Yup.number().required(" Please enter price"),
    priority: Yup.string().required("Please select priority"),
    TAT: Yup.number().required("Please enter Turn Around Time (TAT) in Days"),
    certificateExpiryTime: Yup.number().required(
        "Please enter Certificate Expiry Time in Days"
    ),
    pdfGenerator: Yup.string().required(
        "Please select Certificate Generation Method"
    ),
    dynamicFields: Yup.string()
        .required("Form and Payment fields are required")
        .test("validate-steps", function (value) {
            let parsed;
            try {
                parsed = JSON.parse(value);
            } catch (e) {
                return this.createError({
                    message: "Invalid dynamic fields format",
                });
            }

            const errors = {};

            // Validation for the "Application Form" step (Step 1)
            const appForm = parsed.find((field) => field.id === "appForm");
            if (!appForm) {
                errors.formid = "Form step is required";
            } else {
                if (!appForm.applicationFormId) {
                    errors.formid = "Please select the Form.";
                }
                if (!appForm.imageUrl) {
                    errors.formimage = "Please select form icon.";
                }
            }

            // Validation for the "Upload Document" step (Step 2)
            const docForm = parsed.find((field) => field.id === "docForm");
            if (!docForm) {
                errors.documenticon = "Document step is required";
            } else {
                if (!docForm.imageUrl) {
                    errors.documenticon = "Please select document icon";
                }
                docForm?.requiredDocuments.forEach((data, index) => {
                    if (!data.selectedDocument) {
                        errors[index] = "Please select document";
                    }
                });
            }

            // Validation for the "Declaration & Payment Rules" step (Step 3)
            const declarationForm = parsed.find(
                (field) => field.id === "declaration & paymentRules"
            );
            if (!declarationForm) {
                errors.payment = "Declaration and Payment step is required";
            } else {
                if (!declarationForm.imageUrl) {
                    errors.payment = "Please select payment icon";
                }
            }

            if (Object.keys(errors).length > 0) {
                return this.createError({ message: JSON.stringify(errors) });
            }

            return true;
        }),
});

const cleanHTML = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
};

const ServiceModal = ({
    show,
    handleClose,
    serviceDataById,
    departmentList,
    formList,
    fetchServiceList,
    documentList,
    serviceList,
    viewPermissions,
    createPermission,
    editPermission,
}) => {
    const axiosInstance = useAxios();
    const [loading, setLoading] = useState(false);
    const userEncryptData = localStorage.getItem("userData");
    const userDecryptData = userEncryptData
        ? decrypt({ data: userEncryptData })
        : {};
    const userData = userDecryptData?.data;
    const userId = userData?.id;
    const [formdata, setFormData] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [dynamicFields, setDynamicFields] = useState(
        serviceDataById?.step
            ? JSON.parse(serviceDataById?.step)
            : [
                  {
                      id: "appForm",
                      applicationFormId: "",
                      requiredDocuments: [
                          {
                              selectedDocument: "",
                              isRequired: false,
                              canApply: false,
                              appliedService: "",
                          },
                      ],
                      paymentFormId: "",
                      selectedImage: "",
                      title: "Application Form",
                      imageUrl: "",
                  },
                  {
                      id: "docForm",
                      applicationFormId: "",
                      requiredDocuments: [
                          {
                              selectedDocument: "",
                              isRequired: false,
                              canApply: false,
                              appliedService: "",
                          },
                      ],
                      paymentFormId: "",
                      selectedImage: "",
                      title: "Upload Document",
                      imageUrl: "",
                  },
                  {
                      id: "declaration & paymentRules",
                      applicationFormId: "",
                      requiredDocuments: [
                          {
                              selectedDocument: "",
                              isRequired: false,
                              canApply: false,
                              appliedService: "",
                          },
                      ],
                      paymentFormId: "",
                      selectedImage: "",
                      title: "Declaration & Payment Rules",
                      imageUrl: "",
                  },
              ]
    );

    const [previewshow, setPreviewShow] = useState(false);
    const previewShowToggle = () => {
        setPreviewShow(!previewshow);
    };
    const [searchQuery, setSearchQuery] = useState("");

    const [copiedIndex, setCopiedIndex] = useState(null);

    const copyToClipboard = (text, index) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopiedIndex(index);
            setTimeout(() => setCopiedIndex(null), 2000);
        });
    };

    // Filter data based on the search query for label
    const filteredData = formdata?.filter((item) =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        if (serviceDataById && formList.length > 0) {
            const formData = JSON.parse(serviceDataById?.step);

            let formId = Number(formData[0]?.applicationFormId);

            const selectedForm = formList.find((form) => form.id === formId);

            const formDataParsed =
                selectedForm && JSON.parse(selectedForm.formData);
            const extractedData = formDataParsed
                ?.map((item) => {
                    return {
                        label: item.label,
                        name: item.name,
                    };
                })
                ?.filter((item) => item.label && item.name);

            setFormData(extractedData);
        }
    }, []);

    const addService = async (values) => {
        try {
            setLoading(true);
            const response = await axiosInstance.post(
                `serviceManagement/service/create`,
                {
                    ...values,
                }
            );
            if (response.data?.data) {
                toast.success("Service added successfully.");
                fetchServiceList();
                handleClose();
                setLoading(false);
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                    "Error while creating service, please try again."
            );
            setLoading(false);
            console.error("Something went wrong while create new service");
        }
    };

    const updateService = async (id, values) => {
        try {
            setLoading(true);
            if (id) {
                const response = await axiosInstance.put(
                    `serviceManagement/service/update`,
                    {
                        id: id,
                        ...values,
                    }
                );
                if (response) {
                    toast.success("Service updated successfully.");
                    fetchServiceList();
                    handleClose();
                    setLoading(false);
                }
            }
        } catch (error) {
            setLoading(false);
            toast.error("No changes were made.");
            console.error("Something went wrong while update service");
        }
    };

    // const initialDynamicFields = Array.isArray(serviceDataById?.step)
    // ? serviceDataById.step
    // : JSON.parse(serviceDataById?.step || '[]');

    const renderTooltip = (props) => (
        <Tooltip id="info-tooltip" {...props}>
            Text
        </Tooltip>
    );

    const initialcertificateTemplate = `
            <figure class="table">
            <table>
                <tbody>
                <tr>
                    <th>Example Name</th>
                    <td><strong>@@ExampleValue@@</strong></td>
                </tr>
                <tr>
                    <th>Example Type</th>
                    <td><strong>@@ExampleValue@@</strong></td>
                </tr>
                </tbody>
            </table>
            </figure>
            `;

    const formik = useFormik({
        initialValues: {
            serviceName: serviceDataById?.serviceName || "",
            slug: serviceDataById?.slug || "",
            shortDescription: serviceDataById?.shortDescription || "",
            departmentId: serviceDataById?.departmentId || "",
            price: serviceDataById?.price || "",
            priority: serviceDataById?.priority || "",
            TAT: serviceDataById?.TAT || "",
            certificateExpiryTime: serviceDataById?.certificateExpiryTime || 0,
            status: serviceDataById?.status || "",
            dynamicFields: serviceDataById?.step || dynamicFields,
            certificateTemplate: serviceDataById?.certificateTemplate
                ? serviceDataById?.certificateTemplate
                : initialcertificateTemplate,
            pdfGenerator: serviceDataById?.pdfGenerator || "",
        },
        // validate: validate,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            // values.dynamicFields = JSON.parse(values.dynamicFields);
            if (serviceDataById) {
                await updateService(serviceDataById?.id, values)
                    .then((res) => {
                        setLoading(false);
                        handleClose();
                        fetchServiceList();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                addService(values)
                    .then((res) => {
                        setLoading(false);
                        handleClose();
                        fetchServiceList();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },
    });

    const handleFormChange = (index, formId) => {
        const numericFormId = Number(formId);
        const updatedData = [...dynamicFields];
        updatedData[index].applicationFormId = formId;
        setDynamicFields(updatedData);
        formik.setFieldValue("dynamicFields", JSON.stringify(updatedData));

        const selectedForm = formList.find((form) => form.id === numericFormId);

        const formDataParsed = selectedForm
            ? JSON.parse(selectedForm?.formData)
            : null;
        const extractedData = formDataParsed
            ?.map((item) => {
                return {
                    label: cleanHTML(item.label),
                    name: item.name,
                    // value: item.value
                };
            })
            ?.filter((item) => item.label && item.name);

        //   return extractedData
        setFormData(extractedData);
    };

    const addDocumentField = (fieldIndex) => {
        const updatedFields = [...dynamicFields];
        updatedFields[fieldIndex].requiredDocuments.push({
            selectedDocument: "",
            isRequired: false,
            canApply: false,
            appliedService: "",
        });
        setDynamicFields(updatedFields);
        formik.setFieldValue("dynamicFields", JSON.stringify(updatedFields));
    };

    const handleDocumentChange = (fieldIndex, docIndex, documentId) => {
        const updatedFields = [...dynamicFields];
        updatedFields[fieldIndex].requiredDocuments[docIndex].selectedDocument =
            documentId;
        setDynamicFields(updatedFields);
        formik.setFieldValue("dynamicFields", JSON.stringify(updatedFields));
    };

    const handleCheckboxChange = (index, docIndex, field) => {
        const updatedFields = [...dynamicFields];
        updatedFields[index].requiredDocuments[docIndex][field] =
            !updatedFields[index].requiredDocuments[docIndex][field];
        if (
            field === "canApply" &&
            !updatedFields[index].requiredDocuments[docIndex][field]
        ) {
            updatedFields[index].requiredDocuments[docIndex].appliedService =
                "";
        }
        setDynamicFields(updatedFields);
        formik.setFieldValue("dynamicFields", JSON.stringify(updatedFields));
    };

    const handleAppliedFormChange = (index, docIndex, form) => {
        const updatedFields = [...dynamicFields];
        updatedFields[index].requiredDocuments[docIndex].appliedService = form;
        setDynamicFields(updatedFields);
        formik.setFieldValue("dynamicFields", JSON.stringify(updatedFields));
    };

    useEffect(() => {
        if (serviceDataById) {
            formik.setFieldValue(
                "dynamicFields",
                serviceDataById.dynamicFields
            );
        }
    }, [serviceDataById]);

    const handleRemoveDocumentField = (fieldIndex, docIndex) => {
        const updatedFields = [...dynamicFields];
        updatedFields[fieldIndex].requiredDocuments = updatedFields[
            fieldIndex
        ].requiredDocuments.filter((_, index) => index !== docIndex);
        setDynamicFields(updatedFields);
        formik.setFieldValue("dynamicFields", JSON.stringify(updatedFields));
    };

    const handleImageChange = async (index, event) => {
        const updatedFields = [...dynamicFields];
        const file = event.target.files[0];

        if (file) {
            if (file.size > 102400) {
                toast.error(
                    "File size exceeds 100KB. Please choose a smaller file."
                );
                event.target.value = "";
                updatedFields[index] = {
                    ...updatedFields[index],
                    selectedImage: null,
                    imageUrl: null,
                };

                setDynamicFields(updatedFields);
                formik.setFieldValue("dynamicFields", updatedFields);
                return;
            }

            const formData = new FormData();
            formData.append("viewDocumentName", "Form Icon");
            formData.append("documentFile", file);
            formData.append("userId", userId);
            formData.append("isGenerated", "0");
            formData.append("isShowInDocument", "0");

            try {
                const fileResponse = await axiosInstance.post(
                    "documentService/uploading",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                const uploadedDocumentId = fileResponse?.data?.data?.[0]?.id;
                const imageUrl = fileResponse?.data?.data?.[0]?.documentPath;
                updatedFields[index] = {
                    ...updatedFields[index],
                    selectedImage: uploadedDocumentId,
                    imageUrl: imageUrl,
                };

                setDynamicFields(updatedFields);
                formik.setFieldValue("dynamicFields", updatedFields);
            } catch (error) {
                console.error("Image upload error:", error);
            }
        }
    };

    useEffect(() => {
        formik.setFieldValue("dynamicFields", JSON.stringify(dynamicFields));
    }, [dynamicFields]);

    const departmentOptions =
        departmentList &&
        departmentList.map((department) => ({
            value: department.id,
            label: department.departmentName,
        }));

    const priorityOptions = [
        { value: "0", label: "Standard" },
        { value: "1", label: "Express" },
    ];
    const pdfGenretorOptions = [
        { value: "0", label: "Automatic" },
        { value: "1", label: "Manual" },
    ];

    const parseDynamicFieldErrors = (errors) => {
        try {
            return JSON.parse(errors);
        } catch {
            return {};
        }
    };

    useEffect(() => {
        const selectedDept = departmentList.find(
            (dept) => dept.id === formik.values.departmentId
        );
        setSelectedDepartment(selectedDept || null);
    }, [formik.values.departmentId, departmentList]);

    const errors = formik.errors.dynamicFields
        ? parseDynamicFieldErrors(formik.errors.dynamicFields)
        : {};

    return (
        <>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box header-title d-sm-flex align-items-center justify-content-between pt-lg-4 pt-3">
                                    <h4 className="mb-sm-0">Services</h4>
                                    <div className="page-title-right">
                                        <div className="mb-0 me-2 fs-15 text-muted current-date"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 pe-xxl-4">
                            <div className="row">
                                <div className="col-xxl-12">
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="card border-0 mb-0">
                                            <div className="card-body p-4">
                                                <div className=" ">
                                                    <div className="modal-header  pb-4">
                                                        {!serviceDataById &&
                                                            createPermission && (
                                                                <h4
                                                                    className="modal-title"
                                                                    id="exampleModalgridLabel"
                                                                >
                                                                    Create
                                                                    Service
                                                                </h4>
                                                            )}
                                                        {serviceDataById &&
                                                            !editPermission && (
                                                                <h4
                                                                    className="modal-title"
                                                                    id="exampleModalgridLabel"
                                                                >
                                                                    View Service
                                                                </h4>
                                                            )}
                                                        {editPermission &&
                                                            serviceDataById && (
                                                                <h4
                                                                    className="modal-title"
                                                                    id="exampleModalgridLabel"
                                                                >
                                                                    Update
                                                                    Service
                                                                </h4>
                                                            )}
                                                        <div className="d-flex justify-content-end align-items-center">
                                                            <span
                                                                onClick={
                                                                    handleClose
                                                                }
                                                                className="btn btn-danger"
                                                            >
                                                                <i className="ri-close-line me-1 align-middle lh-0"></i>{" "}
                                                                Cancel
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-xl-4 col-xxl-3 col-lg-6 col-md-6 col-sm-12 mb-2">
                                                                <div>
                                                                    <label
                                                                        htmlFor="tasksTitle-field"
                                                                        className="form-label"
                                                                    >
                                                                        {" "}
                                                                        Service
                                                                        Name{" "}
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter Service Name"
                                                                        value={
                                                                            formik
                                                                                .values
                                                                                .serviceName
                                                                        }
                                                                        {...formik.getFieldProps(
                                                                            "serviceName"
                                                                        )}
                                                                        disabled={
                                                                            (!createPermission &&
                                                                                !editPermission) ||
                                                                            (serviceDataById &&
                                                                                !editPermission)
                                                                        }
                                                                    />
                                                                    {formik
                                                                        .errors
                                                                        .serviceName &&
                                                                        formik
                                                                            .touched
                                                                            .serviceName && (
                                                                            <div className="text-danger error ">
                                                                                {" "}
                                                                                {
                                                                                    formik
                                                                                        .errors
                                                                                        .serviceName
                                                                                }{" "}
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <div className=" col-xl-4 col-xxl-3 col-lg-6 col-md-6 col-sm-12 mb-3">
                                                                <div>
                                                                    <label
                                                                        htmlFor="tasksTitle-field"
                                                                        className="form-label"
                                                                    >
                                                                        {" "}
                                                                        Slug*
                                                                        (Unique
                                                                        and
                                                                        unchangeable
                                                                        after
                                                                        creation){" "}
                                                                    </label>
                                                                    <input
                                                                        disabled={
                                                                            serviceDataById ||
                                                                            (!createPermission &&
                                                                                !editPermission) ||
                                                                            (serviceDataById &&
                                                                                !editPermission)
                                                                        }
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter Slug"
                                                                        value={
                                                                            formik
                                                                                .values
                                                                                .slug
                                                                        }
                                                                        {...formik.getFieldProps(
                                                                            "slug"
                                                                        )}
                                                                    />
                                                                    {formik
                                                                        .errors
                                                                        .slug &&
                                                                        formik
                                                                            .touched
                                                                            .slug && (
                                                                            <div className="text-danger error ">
                                                                                {
                                                                                    formik
                                                                                        .errors
                                                                                        .slug
                                                                                }
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <div className=" col-xl-4 col-xxl-3 col-lg-6 col-md-6 col-sm-12 mb-3">
                                                                <div>
                                                                    <label
                                                                        htmlFor="tasksTitle-field"
                                                                        className="form-label"
                                                                    >
                                                                        {" "}
                                                                        Short
                                                                        Description{" "}
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter Short Description"
                                                                        value={
                                                                            formik
                                                                                .values
                                                                                .shortDescription
                                                                        }
                                                                        {...formik.getFieldProps(
                                                                            "shortDescription"
                                                                        )}
                                                                        disabled={
                                                                            (!createPermission &&
                                                                                !editPermission) ||
                                                                            (serviceDataById &&
                                                                                !editPermission)
                                                                        }
                                                                    />
                                                                    {formik
                                                                        .errors
                                                                        .shortDescription &&
                                                                        formik
                                                                            .touched
                                                                            .shortDescription && (
                                                                            <div className="text-danger error ">
                                                                                {
                                                                                    formik
                                                                                        .errors
                                                                                        .shortDescription
                                                                                }
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <div className=" col-xl-4 col-xxl-3 col-lg-6 col-md-6 col-sm-12 mb-3">
                                                                <div>
                                                                    <label
                                                                        htmlFor="tasksTitle-field"
                                                                        className="form-label"
                                                                    >
                                                                        {" "}
                                                                        Department{" "}
                                                                    </label>

                                                                    <div className="text-start bg-light">
                                                                        <Select
                                                                            id="departmentId"
                                                                            value={
                                                                                departmentOptions.find(
                                                                                    (
                                                                                        option
                                                                                    ) =>
                                                                                        option.value ===
                                                                                        formik
                                                                                            .values
                                                                                            .departmentId
                                                                                ) ||
                                                                                null
                                                                            }
                                                                            onChange={(
                                                                                option
                                                                            ) =>
                                                                                formik.setFieldValue(
                                                                                    "departmentId",
                                                                                    option
                                                                                        ? parseInt(
                                                                                              option.value
                                                                                          )
                                                                                        : ""
                                                                                )
                                                                            }
                                                                            options={
                                                                                departmentOptions
                                                                            }
                                                                            placeholder="Select Department"
                                                                            name="departmentId"
                                                                            styles={{
                                                                                control:
                                                                                    (
                                                                                        provided
                                                                                    ) => ({
                                                                                        ...provided,
                                                                                        cursor: "pointer",
                                                                                    }),
                                                                                menu: (
                                                                                    provided
                                                                                ) => ({
                                                                                    ...provided,
                                                                                    cursor: "pointer",
                                                                                }),
                                                                                option: (
                                                                                    provided
                                                                                ) => ({
                                                                                    ...provided,
                                                                                    cursor: "pointer",
                                                                                }),
                                                                            }}
                                                                            isDisabled={
                                                                                (!createPermission &&
                                                                                    !editPermission) ||
                                                                                (serviceDataById &&
                                                                                    !editPermission)
                                                                            }
                                                                        />
                                                                    </div>

                                                                    {formik
                                                                        .touched
                                                                        .departmentId &&
                                                                        formik
                                                                            .errors
                                                                            .departmentId && (
                                                                            <div className="text-danger error ">
                                                                                {
                                                                                    formik
                                                                                        .errors
                                                                                        .departmentId
                                                                                }
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <div className=" col-xl-4 col-xxl-3 col-lg-6 col-md-6 col-sm-12 mb-3">
                                                                <div>
                                                                    <label
                                                                        htmlFor="tasksTitle-field"
                                                                        className="form-label"
                                                                    >
                                                                        {" "}
                                                                        Price{" "}
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder="Enter Price"
                                                                        value={
                                                                            formik
                                                                                .values
                                                                                .price
                                                                        }
                                                                        {...formik.getFieldProps(
                                                                            "price"
                                                                        )}
                                                                        disabled={
                                                                            (!createPermission &&
                                                                                !editPermission) ||
                                                                            (serviceDataById &&
                                                                                !editPermission)
                                                                        }
                                                                    />
                                                                    {formik
                                                                        .errors
                                                                        .price &&
                                                                        formik
                                                                            .touched
                                                                            .price && (
                                                                            <div className="text-danger error ">
                                                                                {
                                                                                    formik
                                                                                        .errors
                                                                                        .price
                                                                                }
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <div className=" col-xl-4 col-xxl-3 col-lg-6 col-md-6 col-sm-12 mb-3">
                                                                <div>
                                                                    <label
                                                                        htmlFor="tasksTitle-field"
                                                                        className="form-label"
                                                                    >
                                                                        {" "}
                                                                        Priority{" "}
                                                                    </label>
                                                                    <Select
                                                                        id="priority"
                                                                        value={
                                                                            priorityOptions.find(
                                                                                (
                                                                                    option
                                                                                ) =>
                                                                                    option.value ===
                                                                                    formik
                                                                                        .values
                                                                                        .priority
                                                                            ) ||
                                                                            null
                                                                        }
                                                                        onChange={(
                                                                            option
                                                                        ) =>
                                                                            formik.setFieldValue(
                                                                                "priority",
                                                                                option
                                                                                    ? option.value
                                                                                    : ""
                                                                            )
                                                                        }
                                                                        options={
                                                                            priorityOptions
                                                                        }
                                                                        placeholder="Select Priority"
                                                                        name="priority"
                                                                        styles={{
                                                                            control:
                                                                                (
                                                                                    provided
                                                                                ) => ({
                                                                                    ...provided,
                                                                                    cursor: "pointer",
                                                                                }),
                                                                            menu: (
                                                                                provided
                                                                            ) => ({
                                                                                ...provided,
                                                                                cursor: "pointer",
                                                                            }),
                                                                            option: (
                                                                                provided
                                                                            ) => ({
                                                                                ...provided,
                                                                                cursor: "pointer",
                                                                            }),
                                                                        }}
                                                                        isDisabled={
                                                                            (!createPermission &&
                                                                                !editPermission) ||
                                                                            (serviceDataById &&
                                                                                !editPermission)
                                                                        }
                                                                    />
                                                                    {formik
                                                                        .touched
                                                                        .priority &&
                                                                        formik
                                                                            .errors
                                                                            .priority && (
                                                                            <div className="text-danger error ">
                                                                                {
                                                                                    formik
                                                                                        .errors
                                                                                        .priority
                                                                                }
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <div className=" col-xl-4 col-xxl-3 col-lg-6 col-md-6 col-sm-12 mb-3">
                                                                <div>
                                                                    <label
                                                                        htmlFor="tasksTitle-field"
                                                                        className="form-label"
                                                                    >
                                                                        {" "}
                                                                        TAT
                                                                        -Turn
                                                                        Around
                                                                        Time (In
                                                                        Days){" "}
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder="Enter TAT (in Days)"
                                                                        value={
                                                                            formik
                                                                                .values
                                                                                .TAT
                                                                        }
                                                                        {...formik.getFieldProps(
                                                                            "TAT"
                                                                        )}
                                                                        disabled={
                                                                            (!createPermission &&
                                                                                !editPermission) ||
                                                                            (serviceDataById &&
                                                                                !editPermission)
                                                                        }
                                                                    />
                                                                    {formik
                                                                        .errors
                                                                        .TAT &&
                                                                        formik
                                                                            .touched
                                                                            .TAT && (
                                                                            <div className="text-danger error ">
                                                                                {
                                                                                    formik
                                                                                        .errors
                                                                                        .TAT
                                                                                }
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <div className=" col-xl-4 col-xxl-3 col-lg-6 col-md-6 col-sm-12 mb-3">
                                                                <div>
                                                                    <label
                                                                        htmlFor="tasksTitle-field"
                                                                        className="form-label"
                                                                    >
                                                                        {" "}
                                                                        Certificate
                                                                        Generation
                                                                        Method{" "}
                                                                    </label>
                                                                    <Select
                                                                        id="certificateGenerationMethod"
                                                                        value={
                                                                            pdfGenretorOptions.find(
                                                                                (
                                                                                    option
                                                                                ) =>
                                                                                    option.value ===
                                                                                    formik
                                                                                        .values
                                                                                        .pdfGenerator
                                                                            ) ||
                                                                            null
                                                                        }
                                                                        onChange={(
                                                                            option
                                                                        ) =>
                                                                            formik.setFieldValue(
                                                                                "pdfGenerator",
                                                                                option
                                                                                    ? option.value
                                                                                    : ""
                                                                            )
                                                                        }
                                                                        options={
                                                                            pdfGenretorOptions
                                                                        }
                                                                        placeholder="Select Certificate Generation Method"
                                                                        name="certificateGenerationMethod"
                                                                        styles={{
                                                                            control:
                                                                                (
                                                                                    provided
                                                                                ) => ({
                                                                                    ...provided,
                                                                                    cursor: "pointer",
                                                                                }),
                                                                            menu: (
                                                                                provided
                                                                            ) => ({
                                                                                ...provided,
                                                                                cursor: "pointer",
                                                                            }),
                                                                            option: (
                                                                                provided
                                                                            ) => ({
                                                                                ...provided,
                                                                                cursor: "pointer",
                                                                            }),
                                                                        }}
                                                                        isDisabled={
                                                                            (!createPermission &&
                                                                                !editPermission) ||
                                                                            (serviceDataById &&
                                                                                !editPermission)
                                                                        }
                                                                    />
                                                                    {formik
                                                                        .touched
                                                                        .pdfGenerator &&
                                                                        formik
                                                                            .errors
                                                                            .pdfGenerator && (
                                                                            <div className="text-danger error ">
                                                                                {
                                                                                    formik
                                                                                        .errors
                                                                                        .pdfGenerator
                                                                                }
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <div className=" col-xl-4 col-xxl-3 col-lg-6 col-md-6 col-sm-12 mb-3">
                                                                <div>
                                                                    <label
                                                                        htmlFor="tasksTitle-field"
                                                                        className="form-label"
                                                                    >
                                                                        {" "}
                                                                        Certificate
                                                                        Expiry
                                                                        Time (In
                                                                        Days){" "}
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder="Certificate Expiry Time (in Days)"
                                                                        value={
                                                                            formik
                                                                                .values
                                                                                .certificateExpiryTime
                                                                        }
                                                                        {...formik.getFieldProps(
                                                                            "certificateExpiryTime"
                                                                        )}
                                                                        disabled={
                                                                            (!createPermission &&
                                                                                !editPermission) ||
                                                                            (serviceDataById &&
                                                                                !editPermission)
                                                                        }
                                                                    />
                                                                    {formik
                                                                        .errors
                                                                        .certificateExpiryTime &&
                                                                        formik
                                                                            .touched
                                                                            .certificateExpiryTime && (
                                                                            <div className="text-danger error ">
                                                                                {
                                                                                    formik
                                                                                        .errors
                                                                                        .certificateExpiryTime
                                                                                }
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card border-0 mt-4 mb-0">
                                            <div className="card-body p-4">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center mb-4">
                                                                <h4
                                                                    htmlFor="dynamicFields"
                                                                    className="form-label"
                                                                >
                                                                    {" "}
                                                                    Form Steps{" "}
                                                                    <OverlayTrigger
                                                                        placement="right"
                                                                        overlay={
                                                                            renderTooltip
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                infotool
                                                                            }
                                                                            alt="info"
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                        />
                                                                    </OverlayTrigger>
                                                                </h4>
                                                            </div>

                                                            {/* {dynamicFields.map(
                                                                (field, index) => (<>
                                                                    <div key={index} className="border rounded-2 border-1 p-3 p-sm-4 mb-4" >
                                                                        <div className="d-flex justify-content-between align-items-center mb-3 mb-sm-0">
                                                                            <div className="mb-3"> {" "} <h5> {" "} Step{" "} {index + 1} {` - ${field?.title}`} </h5> </div>
                                                                        </div>
                                                                        {index ===
                                                                            0 && (
                                                                                <div className="mb-3 row">
                                                                                    <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-12">
                                                                                        <div className="mb-2">
                                                                                            <label
                                                                                                htmlFor={`formSelect-${index}`}
                                                                                                className="form-label"
                                                                                            >
                                                                                                {" "}
                                                                                                Select
                                                                                                Form{" "}
                                                                                            </label>
                                                                                            <Form.Select
                                                                                                id={`formSelect-${index}`}
                                                                                                value={
                                                                                                    field.applicationFormId
                                                                                                }
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) => {
                                                                                                    handleFormChange(
                                                                                                        index,
                                                                                                        e
                                                                                                            .target
                                                                                                            .value
                                                                                                    );
                                                                                                }}
                                                                                                onBlur={() =>
                                                                                                    formik.setFieldTouched(
                                                                                                        `dynamicFields[${index}].applicationFormId`,
                                                                                                        true
                                                                                                    )
                                                                                                }
                                                                                                disabled={
                                                                                                    (!createPermission &&
                                                                                                        !editPermission) ||
                                                                                                    (serviceDataById &&
                                                                                                        !editPermission)
                                                                                                }
                                                                                            >
                                                                                                <option value="">
                                                                                                    Select
                                                                                                    Form
                                                                                                </option>
                                                                                                {formList.map(
                                                                                                    (
                                                                                                        form
                                                                                                    ) => (
                                                                                                        <option
                                                                                                            key={
                                                                                                                form.id
                                                                                                            }
                                                                                                            value={
                                                                                                                form.id
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                form.formName
                                                                                                            }{" "}
                                                                                                            (
                                                                                                            {
                                                                                                                form.formSlug
                                                                                                            }

                                                                                                            )
                                                                                                        </option>
                                                                                                    )
                                                                                                )}
                                                                                            </Form.Select>
                                                                                        </div>
                                                                                        {formik
                                                                                            .touched
                                                                                            .dynamicFields &&
                                                                                            formik
                                                                                                .errors
                                                                                                .dynamicFields && (
                                                                                                <div className="text-danger error ">
                                                                                                    {errors.formid && (
                                                                                                        <p className="mb-0">
                                                                                                            {
                                                                                                                errors.formid
                                                                                                            }
                                                                                                        </p>
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                    </div>

                                                                                    <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-12">
                                                                                        <div className="mb-3">
                                                                                            <label
                                                                                                htmlFor={`titleInput-${index}`}
                                                                                                className="form-label"
                                                                                            >
                                                                                                {" "}
                                                                                                Title{" "}
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                id={`titleInput-${index}`}
                                                                                                value={
                                                                                                    field.title
                                                                                                }
                                                                                                disabled
                                                                                                className="form-control"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-12">
                                                                                        <div className="mb-2 d-flex align-items-end">
                                                                                            <div className="w-100">
                                                                                                <label
                                                                                                    htmlFor={`documentImage-${index}`}
                                                                                                    className="form-label"
                                                                                                >
                                                                                                    {" "}
                                                                                                    Select
                                                                                                    Form
                                                                                                    Icon{" "}
                                                                                                </label>
                                                                                                <input
                                                                                                    type="file"
                                                                                                    id={`imageUpload-${index}`}
                                                                                                    accept="image/png, image/svg+xml"
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        handleImageChange(
                                                                                                            index,
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                    className="form-control"
                                                                                                    disabled={
                                                                                                        (!createPermission &&
                                                                                                            !editPermission) ||
                                                                                                        (serviceDataById &&
                                                                                                            !editPermission)
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            {field.imageUrl && (
                                                                                                <img
                                                                                                    src={
                                                                                                        field.imageUrl
                                                                                                    }
                                                                                                    alt="Form Preview"
                                                                                                    className="ms-2 rounded-2"
                                                                                                    style={{
                                                                                                        width: "40px",
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                        {formik
                                                                                            .touched
                                                                                            .dynamicFields &&
                                                                                            formik
                                                                                                .errors
                                                                                                .dynamicFields && (
                                                                                                <div className="text-danger error ">
                                                                                                    {errors.formimage && (
                                                                                                        <p className="mb-0">
                                                                                                            {
                                                                                                                errors.formimage
                                                                                                            }
                                                                                                        </p>
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                            )}

                                                                        {index ===
                                                                            1 && (
                                                                                <>
                                                                                    <div className="dynamic-field row">
                                                                                        {field.requiredDocuments.map((doc, docIndex) => (
                                                                                            <div key={docIndex} className="mb-4 col-xl-4  col-xxl-3  col-lg-6 col-md-6 " >

                                                                                                <div className="p-3 p-md-4 bg-dark-subtle h-100 rounded-4">
                                                                                                   
                                                                                                    <h5 className="mb-3"> {" "} Documents{" "} </h5>

                                                                                                    <div className="col-12">
                                                                                                        <div className="form-check form-check-inline mb-3">
                                                                                                            <input className="form-check-input" type="checkbox" id={`isRequired-${index}-${docIndex}`}
                                                                                                                checked={doc.isRequired}
                                                                                                                onChange={() => handleCheckboxChange(index, docIndex, "isRequired")}
                                                                                                                disabled={(!createPermission && !editPermission) || (serviceDataById && !editPermission)}
                                                                                                            />
                                                                                                            <label className="form-check-label" htmlFor={`isRequired-${index}-${docIndex}`} >
                                                                                                                {" "} Is Required{" "}
                                                                                                            </label>
                                                                                                        </div>
                                                                                                        <div className="form-check form-check-inline mb-3">
                                                                                                            <input className="form-check-input" type="checkbox" id={`canApply-${index}-${docIndex}`} checked={doc.canApply}
                                                                                                                onChange={() => handleCheckboxChange(index, docIndex, "canApply")}
                                                                                                                disabled={(!createPermission && !editPermission) || (serviceDataById && !editPermission)}
                                                                                                            />
                                                                                                            <label className="form-check-label" htmlFor={`canApply-${index}-${docIndex}`} >
                                                                                                                {" "} Can Apply{" "}
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 ">
                                                                                                        <div className="mb-2">
                                                                                                            <label htmlFor={`documentSelect-${index}-${docIndex}`} className="form-label" >
                                                                                                                {" "} Select Document{" "}
                                                                                                            </label>
                                                                                                            <Form.Select
                                                                                                                id={`documentSelect-${index}-${docIndex}`} value={doc.selectedDocument}
                                                                                                                onChange={(e) =>
                                                                                                                    handleDocumentChange(index, docIndex, e.target.value)
                                                                                                                }
                                                                                                                disabled={(!createPermission && !editPermission) || (serviceDataById && !editPermission)}
                                                                                                            >
                                                                                                                <option value=""> {" "} Select Document{" "} </option>
                                                                                                                {documentList.map(
                                                                                                                    (document) => (
                                                                                                                        <option key={document.id}
                                                                                                                            value={JSON.stringify(document)}
                                                                                                                            disabled={field.requiredDocuments.some((d, idx) => d.selectedDocument === document.id && idx !== docIndex)} >
                                                                                                                            {document.documentName}
                                                                                                                        </option>
                                                                                                                    )
                                                                                                                )}
                                                                                                            </Form.Select>
                                                                                                        </div>
                                                                                                        {formik.touched.dynamicFields && formik.errors.dynamicFields && (
                                                                                                            <div className="text-danger error ">
                                                                                                                {errors[docIndex] && (
                                                                                                                    <p className="mb-0">
                                                                                                                        {errors[docIndex]}
                                                                                                                    </p>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>

                                                                                                    {docIndex ===
                                                                                                        0 && (
                                                                                                            <>
                                                                                                                <div className="col-12">
                                                                                                                    <div className="mb-3">
                                                                                                                        <label htmlFor={`titleInput-${index}`} className="form-label" >
                                                                                                                            {" "} Title{" "}
                                                                                                                        </label>
                                                                                                                        <input type="text" id={`titleInput-${index}`} value={field.title}
                                                                                                                            disabled className="form-control"
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-12">
                                                                                                                    <div className="mb-2 d-flex align-items-end">
                                                                                                                        <div className="w-100">
                                                                                                                            <label htmlFor={`documentImage-${index}-${docIndex}`} className="form-label" >
                                                                                                                                {" "} Select Document Image{" "}
                                                                                                                            </label>
                                                                                                                            <input type="file" id={`documentImage-${index}-${docIndex}`}
                                                                                                                                accept="image/png, image/svg+xml" onChange={(e) => handleImageChange(index, e)} className="form-control"
                                                                                                                                disabled={(!createPermission && !editPermission) || (serviceDataById && !editPermission)}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        {doc.image && (
                                                                                                                            <img src={URL.createObjectURL(doc.image)} alt="Document" style={{ width: "100px", height: "auto", }} />
                                                                                                                        )}
                                                                                                                        {field.imageUrl && (
                                                                                                                            <img src={field.imageUrl} alt="Document Preview" class="ms-2 rounded-2" style={{ width: "40px", }} />
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                    {formik.touched.dynamicFields && formik.errors.dynamicFields && (
                                                                                                                        <div className="text-danger error ">
                                                                                                                            {errors.documenticon && (
                                                                                                                                <p className="mb-0"> {errors.documenticon} </p>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )}
                                                                                                    {doc.canApply && (
                                                                                                        <div className="col-sm-12">
                                                                                                            <div className="mb-2">
                                                                                                                <label htmlFor={`appliedFormSelect-${index}-${docIndex}`} className="form-label" >
                                                                                                                    {" "} Applied Service{" "}
                                                                                                                </label>
                                                                                                                <Form.Select
                                                                                                                    id={`appliedFormSelect-${index}-${docIndex}`} value={doc.appliedService}
                                                                                                                    onChange={(e) => handleAppliedFormChange(index, docIndex, e.target.value)}
                                                                                                                    disabled={(!createPermission && !editPermission) || (serviceDataById && !editPermission)}
                                                                                                                >
                                                                                                                    <option value=""> {" "} Select Applied Service{" "} </option>
                                                                                                                    {serviceList.map((service) => (
                                                                                                                        <option key={service.id} value={service.slug} disabled={field.requiredDocuments.some((d, idx) => d.appliedService === service.id && idx !== docIndex)} >
                                                                                                                            {service.serviceName}{" "} {`(${service.slug})`}{" "} {`(${service.currentVersion})`}
                                                                                                                        </option>
                                                                                                                    )
                                                                                                                    )}
                                                                                                                </Form.Select>
                                                                                                            </div>
                                                                                                            {formik.touched.dynamicFields && formik.errors.dynamicFields && (
                                                                                                                <div className="text-danger error ">
                                                                                                                    {errors.apply && (<p className="mb-0"> {errors.apply} </p>)}
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    )}
                                                                                                    {((!serviceDataById && createPermission) || (serviceDataById && editPermission)) && (
                                                                                                        <div className="col-12 text-end ms-auto d-flex align-items-end justify-content-sm-end">
                                                                                                            <div className=" mt-3">
                                                                                                                {docIndex !==
                                                                                                                    0 && (
                                                                                                                        <Button variant="danger" onClick={() => handleRemoveDocumentField(index, docIndex)} >
                                                                                                                    <i class="ri-delete-bin-line"></i>
                                                                                                                        </Button>
                                                                                                                    )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                        )}

                                                                                        {createPermission &&
                                                                                            editPermission && (
                                                                                                <Button className="btn btn-primary mt-sm-3" onClick={() => addDocumentField(index)} >
                                                                                                    Add More Documents
                                                                                                </Button>
                                                                                            )}
                                                                                    </div>
                                                                                </>
                                                                            )}

                                                                        {index ===
                                                                            2 && (
                                                                                <>
                                                                                    <div className="mb-3 row">
                                                                                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-12">
                                                                                            <div className="mb-2 d-flex align-items-end">
                                                                                                <div className="w-100">
                                                                                                    <label htmlFor={`documentImage-${index}`} className="form-label" >
                                                                                                        {" "}
                                                                                                        Select
                                                                                                        Payment
                                                                                                        Icon{" "}
                                                                                                    </label>
                                                                                                    <input
                                                                                                        type="file"
                                                                                                        id={`documentImage-${index}`}
                                                                                                        accept="image/png, image/svg+xml"
                                                                                                        onChange={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            handleImageChange(
                                                                                                                index,
                                                                                                                e
                                                                                                            )
                                                                                                        }
                                                                                                        className="form-control"
                                                                                                        disabled={
                                                                                                            (!createPermission &&
                                                                                                                !editPermission) ||
                                                                                                            (serviceDataById &&
                                                                                                                !editPermission)
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                                {field.imageUrl && (
                                                                                                    <img
                                                                                                        src={
                                                                                                            field.imageUrl
                                                                                                        }
                                                                                                        class="ms-2 rounded-2"
                                                                                                        alt="Payment Preview"
                                                                                                        style={{
                                                                                                            width: "40px",
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                            </div>
                                                                                            {formik
                                                                                                .touched
                                                                                                .dynamicFields &&
                                                                                                formik
                                                                                                    .errors
                                                                                                    .dynamicFields && (
                                                                                                    <div className="text-danger error ">
                                                                                                        {errors.payment && (
                                                                                                            <p className="mb-0">
                                                                                                                {
                                                                                                                    errors.payment
                                                                                                                }
                                                                                                            </p>
                                                                                                        )}
                                                                                                    </div>
                                                                                                )}
                                                                                        </div>
                                                                                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-12">
                                                                                            <label
                                                                                                htmlFor={`titleInput-${index}`}
                                                                                                className="form-label"
                                                                                            >
                                                                                                {" "}
                                                                                                Title{" "}
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                id={`titleInput-${index}`}
                                                                                                value={
                                                                                                    field.title
                                                                                                }
                                                                                                disabled
                                                                                                className="form-control"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                    </div>
                                                                </>
                                                                )
                                                            )} */}
                                                            <Tabs
                                                                defaultActiveKey="0"
                                                                id="dynamic-fields-tabs"
                                                                className="mb-3"
                                                            >
                                                                {dynamicFields.map(
                                                                    (
                                                                        field,
                                                                        index
                                                                    ) => (
                                                                        <Tab
                                                                            eventKey={index.toString()}
                                                                            title={
                                                                                <>
                                                                                    Step{" "}
                                                                                    {index +
                                                                                        1}{" "}
                                                                                    -{" "}
                                                                                    {
                                                                                        field?.title
                                                                                    }
                                                                                    {formik
                                                                                        .touched
                                                                                        .dynamicFields &&
                                                                                        formik
                                                                                            .errors
                                                                                            .dynamicFields &&
                                                                                        formik
                                                                                            .errors
                                                                                            .dynamicFields[
                                                                                            index
                                                                                        ] &&
                                                                                        !(field.applicationFormId && field.imageUrl)  &&
                                                                                        !field.imageUrl && 
                                                                                        "*"}
                                                                                </>
                                                                            }
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <div className="border rounded-2 border-1 p-3 p-sm-4 mb-4">
                                                                                <div className="d-flex justify-content-between align-items-center">
                                                                                    <div className="mb-3">
                                                                                        <h5>
                                                                                            Step{" "}
                                                                                            {index +
                                                                                                1}{" "}
                                                                                            -{" "}
                                                                                            {
                                                                                                field?.title
                                                                                            }
                                                                                        </h5>
                                                                                        {index ===
                                                                                            1 && (
                                                                                            <>
                                                                                                <div className="col-12">
                                                                                                    <div className="mb-3">
                                                                                                        <label
                                                                                                            htmlFor={`titleInput-${index}`}
                                                                                                            className="form-label"
                                                                                                        >
                                                                                                            {" "}
                                                                                                            Title{" "}
                                                                                                        </label>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            id={`titleInput-${index}`}
                                                                                                            value={
                                                                                                                field.title
                                                                                                            }
                                                                                                            disabled
                                                                                                            className="form-control"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-12">
                                                                                                    <div className="mb-2 d-flex align-items-end">
                                                                                                        <div className="w-100">
                                                                                                            <label
                                                                                                                htmlFor={`documentImage-${index}`}
                                                                                                                className="form-label"
                                                                                                            >
                                                                                                                {" "}
                                                                                                                Select
                                                                                                                Document
                                                                                                                Image{" "}
                                                                                                            </label>
                                                                                                            <input
                                                                                                                type="file"
                                                                                                                id={`documentImage-${index}`}
                                                                                                                accept="image/svg+xml"
                                                                                                                onChange={(
                                                                                                                    e
                                                                                                                ) =>
                                                                                                                    handleImageChange(
                                                                                                                        index,
                                                                                                                        e
                                                                                                                    )
                                                                                                                }
                                                                                                                className="form-control"
                                                                                                                disabled={
                                                                                                                    (!createPermission &&
                                                                                                                        !editPermission) ||
                                                                                                                    (serviceDataById &&
                                                                                                                        !editPermission)
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                        {field.image && (
                                                                                                            <img
                                                                                                                src={URL.createObjectURL(
                                                                                                                    field.image
                                                                                                                )}
                                                                                                                alt="Document"
                                                                                                                style={{
                                                                                                                    width: "100px",
                                                                                                                    height: "auto",
                                                                                                                }}
                                                                                                            />
                                                                                                        )}
                                                                                                        {field.imageUrl && (
                                                                                                            <img
                                                                                                                src={
                                                                                                                    field.imageUrl
                                                                                                                }
                                                                                                                alt="Document Preview"
                                                                                                                className="ms-2 rounded-2"
                                                                                                                style={{
                                                                                                                    width: "40px",
                                                                                                                }}
                                                                                                            />
                                                                                                        )}
                                                                                                    </div>
                                                                                                    {formik
                                                                                                        .touched
                                                                                                        .dynamicFields &&
                                                                                                        formik
                                                                                                            .errors
                                                                                                            .dynamicFields && (
                                                                                                            <div className="text-danger error">
                                                                                                                {errors.documenticon && (
                                                                                                                    <p className="mb-0">
                                                                                                                        {" "}
                                                                                                                        {
                                                                                                                            errors.documenticon
                                                                                                                        }{" "}
                                                                                                                    </p>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        )}
                                                                                                </div>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                </div>

                                                                                {index ===
                                                                                    0 && (
                                                                                    <div className="mb-3 row">
                                                                                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-12">
                                                                                            <div className="mb-2">
                                                                                                <label
                                                                                                    htmlFor={`formSelect-${index}`}
                                                                                                    className="form-label"
                                                                                                >
                                                                                                    {" "}
                                                                                                    Select
                                                                                                    Form{" "}
                                                                                                </label>
                                                                                                <Form.Select
                                                                                                    id={`formSelect-${index}`}
                                                                                                    value={
                                                                                                        field.applicationFormId
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) => {
                                                                                                        handleFormChange(
                                                                                                            index,
                                                                                                            e
                                                                                                                .target
                                                                                                                .value
                                                                                                        );
                                                                                                    }}
                                                                                                    onBlur={() =>
                                                                                                        formik.setFieldTouched(
                                                                                                            `dynamicFields[${index}].applicationFormId`,
                                                                                                            true
                                                                                                        )
                                                                                                    }
                                                                                                    disabled={
                                                                                                        (!createPermission &&
                                                                                                            !editPermission) ||
                                                                                                        (serviceDataById &&
                                                                                                            !editPermission)
                                                                                                    }
                                                                                                >
                                                                                                    <option value="">
                                                                                                        Select
                                                                                                        Form
                                                                                                    </option>
                                                                                                    {formList.map(
                                                                                                        (
                                                                                                            form
                                                                                                        ) => (
                                                                                                            <option
                                                                                                                key={
                                                                                                                    form.id
                                                                                                                }
                                                                                                                value={
                                                                                                                    form.id
                                                                                                                }
                                                                                                            >
                                                                                                                {
                                                                                                                    form.formName
                                                                                                                }{" "}
                                                                                                                (
                                                                                                                {
                                                                                                                    form.formSlug
                                                                                                                }

                                                                                                                )
                                                                                                            </option>
                                                                                                        )
                                                                                                    )}
                                                                                                </Form.Select>
                                                                                            </div>
                                                                                            {formik
                                                                                                .touched
                                                                                                .dynamicFields &&
                                                                                                formik
                                                                                                    .errors
                                                                                                    .dynamicFields && (
                                                                                                    <div className="text-danger error ">
                                                                                                        {errors.formid && (
                                                                                                            <p className="mb-0">
                                                                                                                {
                                                                                                                    errors.formid
                                                                                                                }
                                                                                                            </p>
                                                                                                        )}
                                                                                                    </div>
                                                                                                )}
                                                                                        </div>

                                                                                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-12">
                                                                                            <div className="mb-3">
                                                                                                <label
                                                                                                    htmlFor={`titleInput-${index}`}
                                                                                                    className="form-label"
                                                                                                >
                                                                                                    {" "}
                                                                                                    Title{" "}
                                                                                                </label>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    id={`titleInput-${index}`}
                                                                                                    value={
                                                                                                        field.title
                                                                                                    }
                                                                                                    disabled
                                                                                                    className="form-control"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-12">
                                                                                            <div className="mb-2 d-flex align-items-end">
                                                                                                <div className="w-100">
                                                                                                    <label
                                                                                                        htmlFor={`documentImage-${index}`}
                                                                                                        className="form-label"
                                                                                                    >
                                                                                                        {" "}
                                                                                                        Select
                                                                                                        Form
                                                                                                        Icon{" "}
                                                                                                    </label>
                                                                                                    <input
                                                                                                        type="file"
                                                                                                        id={`imageUpload-${index}`}
                                                                                                        accept="image/svg+xml"
                                                                                                        onChange={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            handleImageChange(
                                                                                                                index,
                                                                                                                e
                                                                                                            )
                                                                                                        }
                                                                                                        className="form-control"
                                                                                                        disabled={
                                                                                                            (!createPermission &&
                                                                                                                !editPermission) ||
                                                                                                            (serviceDataById &&
                                                                                                                !editPermission)
                                                                                                        }
                                                                                                        //   onBlur={() => formik.setFieldTouched('dynamicFields', true)}
                                                                                                    />
                                                                                                </div>
                                                                                                {field.imageUrl && (
                                                                                                    <img
                                                                                                        src={
                                                                                                            field.imageUrl
                                                                                                        }
                                                                                                        alt="Form Preview"
                                                                                                        className="ms-2 rounded-2"
                                                                                                        style={{
                                                                                                            width: "40px",
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                            </div>
                                                                                            {formik
                                                                                                .touched
                                                                                                .dynamicFields &&
                                                                                                formik
                                                                                                    .errors
                                                                                                    .dynamicFields && (
                                                                                                    <div className="text-danger error ">
                                                                                                        {errors.formimage && (
                                                                                                            <p className="mb-0">
                                                                                                                {
                                                                                                                    errors.formimage
                                                                                                                }
                                                                                                            </p>
                                                                                                        )}
                                                                                                    </div>
                                                                                                )}
                                                                                        </div>
                                                                                        {/* {formik
                                                                                        .touched
                                                                                        .dynamicFields &&
                                                                                        formik
                                                                                            .errors
                                                                                            .dynamicFields && (
                                                                                            <div className="text-danger error ">
                                                                                                {errors.form && (
                                                                                                    <p>
                                                                                                        {
                                                                                                            errors.form
                                                                                                        }
                                                                                                    </p>
                                                                                                )}
                                                                                            </div>
                                                                                        )} */}
                                                                                    </div>
                                                                                )}

                                                                                {index ===
                                                                                    1 && (
                                                                                    <>
                                                                                        <div className="dynamic-field row">
                                                                                            {field.requiredDocuments.map(
                                                                                                (
                                                                                                    doc,
                                                                                                    docIndex
                                                                                                ) => (
                                                                                                    <div
                                                                                                        key={
                                                                                                            docIndex
                                                                                                        }
                                                                                                        className="mb-4 col-xl-4 col-xxl-3 col-lg-6 col-md-6"
                                                                                                    >
                                                                                                        <div className="p-3 p-md-4 bg-dark-subtle h-100 rounded-4">
                                                                                                            <h5 className="mb-3">
                                                                                                                {" "}
                                                                                                                Documents{" "}
                                                                                                            </h5>

                                                                                                            <div className="col-12">
                                                                                                                <div className="form-check form-check-inline mb-3">
                                                                                                                    <input
                                                                                                                        className="form-check-input"
                                                                                                                        type="checkbox"
                                                                                                                        id={`isRequired-${index}-${docIndex}`}
                                                                                                                        checked={
                                                                                                                            doc.isRequired
                                                                                                                        }
                                                                                                                        onChange={() =>
                                                                                                                            handleCheckboxChange(
                                                                                                                                index,
                                                                                                                                docIndex,
                                                                                                                                "isRequired"
                                                                                                                            )
                                                                                                                        }
                                                                                                                        disabled={
                                                                                                                            (!createPermission &&
                                                                                                                                !editPermission) ||
                                                                                                                            (serviceDataById &&
                                                                                                                                !editPermission)
                                                                                                                        }
                                                                                                                    />
                                                                                                                    <label
                                                                                                                        className="form-check-label"
                                                                                                                        htmlFor={`isRequired-${index}-${docIndex}`}
                                                                                                                    >
                                                                                                                        {" "}
                                                                                                                        Is
                                                                                                                        Required{" "}
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                                <div className="form-check form-check-inline mb-3">
                                                                                                                    <input
                                                                                                                        className="form-check-input"
                                                                                                                        type="checkbox"
                                                                                                                        id={`canApply-${index}-${docIndex}`}
                                                                                                                        checked={
                                                                                                                            doc.canApply
                                                                                                                        }
                                                                                                                        onChange={() =>
                                                                                                                            handleCheckboxChange(
                                                                                                                                index,
                                                                                                                                docIndex,
                                                                                                                                "canApply"
                                                                                                                            )
                                                                                                                        }
                                                                                                                        disabled={
                                                                                                                            (!createPermission &&
                                                                                                                                !editPermission) ||
                                                                                                                            (serviceDataById &&
                                                                                                                                !editPermission)
                                                                                                                        }
                                                                                                                    />
                                                                                                                    <label
                                                                                                                        className="form-check-label"
                                                                                                                        htmlFor={`canApply-${index}-${docIndex}`}
                                                                                                                    >
                                                                                                                        {" "}
                                                                                                                        Can
                                                                                                                        Apply{" "}
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="col-12">
                                                                                                                <div className="mb-2">
                                                                                                                    <label
                                                                                                                        htmlFor={`documentSelect-${index}-${docIndex}`}
                                                                                                                        className="form-label"
                                                                                                                    >
                                                                                                                        {" "}
                                                                                                                        Select
                                                                                                                        Document{" "}
                                                                                                                    </label>
                                                                                                                    <Form.Select
                                                                                                                        id={`documentSelect-${index}-${docIndex}`}
                                                                                                                        value={
                                                                                                                            doc.selectedDocument
                                                                                                                        }
                                                                                                                        onChange={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            handleDocumentChange(
                                                                                                                                index,
                                                                                                                                docIndex,
                                                                                                                                e
                                                                                                                                    .target
                                                                                                                                    .value
                                                                                                                            )
                                                                                                                        }
                                                                                                                        disabled={
                                                                                                                            (!createPermission &&
                                                                                                                                !editPermission) ||
                                                                                                                            (serviceDataById &&
                                                                                                                                !editPermission)
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <option value="">
                                                                                                                            {" "}
                                                                                                                            Select
                                                                                                                            Document{" "}
                                                                                                                        </option>
                                                                                                                        {documentList.map(
                                                                                                                            (
                                                                                                                                document
                                                                                                                            ) => (
                                                                                                                                <option
                                                                                                                                    key={
                                                                                                                                        document.id
                                                                                                                                    }
                                                                                                                                    value={JSON.stringify(
                                                                                                                                        document
                                                                                                                                    )}
                                                                                                                                    disabled={field.requiredDocuments.some(
                                                                                                                                        (
                                                                                                                                            d,
                                                                                                                                            idx
                                                                                                                                        ) =>
                                                                                                                                            d.selectedDocument ===
                                                                                                                                                document.id &&
                                                                                                                                            idx !==
                                                                                                                                                docIndex
                                                                                                                                    )}
                                                                                                                                >
                                                                                                                                    {
                                                                                                                                        document.documentName
                                                                                                                                    }
                                                                                                                                </option>
                                                                                                                            )
                                                                                                                        )}
                                                                                                                    </Form.Select>
                                                                                                                </div>
                                                                                                                {formik
                                                                                                                    .touched
                                                                                                                    .dynamicFields &&
                                                                                                                    formik
                                                                                                                        .errors
                                                                                                                        .dynamicFields && (
                                                                                                                        <div className="text-danger error ">
                                                                                                                            {errors[
                                                                                                                                docIndex
                                                                                                                            ] && (
                                                                                                                                <p className="mb-0">
                                                                                                                                    {
                                                                                                                                        errors[
                                                                                                                                            docIndex
                                                                                                                                        ]
                                                                                                                                    }
                                                                                                                                </p>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                            </div>

                                                                                                            {doc.canApply && (
                                                                                                                <div className="col-sm-12">
                                                                                                                    <div className="mb-2">
                                                                                                                        <label
                                                                                                                            htmlFor={`appliedFormSelect-${index}-${docIndex}`}
                                                                                                                            className="form-label"
                                                                                                                        >
                                                                                                                            {" "}
                                                                                                                            Applied
                                                                                                                            Service{" "}
                                                                                                                        </label>
                                                                                                                        <Form.Select
                                                                                                                            id={`appliedFormSelect-${index}-${docIndex}`}
                                                                                                                            value={
                                                                                                                                doc.appliedService
                                                                                                                            }
                                                                                                                            onChange={(
                                                                                                                                e
                                                                                                                            ) =>
                                                                                                                                handleAppliedFormChange(
                                                                                                                                    index,
                                                                                                                                    docIndex,
                                                                                                                                    e
                                                                                                                                        .target
                                                                                                                                        .value
                                                                                                                                )
                                                                                                                            }
                                                                                                                            disabled={
                                                                                                                                (!createPermission &&
                                                                                                                                    !editPermission) ||
                                                                                                                                (serviceDataById &&
                                                                                                                                    !editPermission)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <option value="">
                                                                                                                                {" "}
                                                                                                                                Select
                                                                                                                                Applied
                                                                                                                                Service{" "}
                                                                                                                            </option>
                                                                                                                            {serviceList.map(
                                                                                                                                (
                                                                                                                                    service
                                                                                                                                ) => (
                                                                                                                                    <option
                                                                                                                                        key={
                                                                                                                                            service.id
                                                                                                                                        }
                                                                                                                                        value={
                                                                                                                                            service.slug
                                                                                                                                        }
                                                                                                                                        disabled={field.requiredDocuments.some(
                                                                                                                                            (
                                                                                                                                                d,
                                                                                                                                                idx
                                                                                                                                            ) =>
                                                                                                                                                d.appliedService ===
                                                                                                                                                    service.id &&
                                                                                                                                                idx !==
                                                                                                                                                    docIndex
                                                                                                                                        )}
                                                                                                                                    >
                                                                                                                                        {
                                                                                                                                            service.serviceName
                                                                                                                                        }{" "}
                                                                                                                                        {`(${service.slug})`}{" "}
                                                                                                                                        {`(${service.currentVersion})`}
                                                                                                                                    </option>
                                                                                                                                )
                                                                                                                            )}
                                                                                                                        </Form.Select>
                                                                                                                    </div>
                                                                                                                    {formik
                                                                                                                        .touched
                                                                                                                        .dynamicFields &&
                                                                                                                        formik
                                                                                                                            .errors
                                                                                                                            .dynamicFields && (
                                                                                                                            <div className="text-danger error ">
                                                                                                                                {errors.apply && (
                                                                                                                                    <p className="mb-0">
                                                                                                                                        {" "}
                                                                                                                                        {
                                                                                                                                            errors.apply
                                                                                                                                        }{" "}
                                                                                                                                    </p>
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                </div>
                                                                                                            )}

                                                                                                            {((!serviceDataById &&
                                                                                                                createPermission) ||
                                                                                                                (serviceDataById &&
                                                                                                                    editPermission)) && (
                                                                                                                <div className="col-12 text-end ms-auto d-flex align-items-end justify-content-sm-end">
                                                                                                                    <div className=" mt-3">
                                                                                                                        {docIndex !==
                                                                                                                            0 && (
                                                                                                                            <Button
                                                                                                                                variant="danger"
                                                                                                                                onClick={() =>
                                                                                                                                    handleRemoveDocumentField(
                                                                                                                                        index,
                                                                                                                                        docIndex
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <i className="ri-delete-bin-line"></i>
                                                                                                                            </Button>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            )}

                                                                                            {createPermission &&
                                                                                                editPermission && (
                                                                                                    <Button
                                                                                                        className="btn btn-primary mt-sm-3"
                                                                                                        onClick={() =>
                                                                                                            addDocumentField(
                                                                                                                index
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        Add
                                                                                                        More
                                                                                                        Documents
                                                                                                    </Button>
                                                                                                )}
                                                                                        </div>
                                                                                    </>
                                                                                )}

                                                                                {index ===
                                                                                    2 && (
                                                                                    <>
                                                                                        <div className="mb-3 row">
                                                                                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-12">
                                                                                                <div className="mb-2 d-flex align-items-end">
                                                                                                    <div className="w-100">
                                                                                                        <label
                                                                                                            htmlFor={`documentImage-${index}`}
                                                                                                            className="form-label"
                                                                                                        >
                                                                                                            {" "}
                                                                                                            Select
                                                                                                            Payment
                                                                                                            Icon{" "}
                                                                                                        </label>
                                                                                                        <input
                                                                                                            type="file"
                                                                                                            id={`documentImage-${index}`}
                                                                                                            accept="image/svg+xml"
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                handleImageChange(
                                                                                                                    index,
                                                                                                                    e
                                                                                                                )
                                                                                                            }
                                                                                                            className="form-control"
                                                                                                            disabled={
                                                                                                                (!createPermission &&
                                                                                                                    !editPermission) ||
                                                                                                                (serviceDataById &&
                                                                                                                    !editPermission)
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                    {field.imageUrl && (
                                                                                                        <img
                                                                                                            src={
                                                                                                                field.imageUrl
                                                                                                            }
                                                                                                            class="ms-2 rounded-2"
                                                                                                            alt="Payment Preview"
                                                                                                            style={{
                                                                                                                width: "40px",
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                                </div>
                                                                                                {formik
                                                                                                    .touched
                                                                                                    .dynamicFields &&
                                                                                                    formik
                                                                                                        .errors
                                                                                                        .dynamicFields && (
                                                                                                        <div className="text-danger error ">
                                                                                                            {errors.payment && (
                                                                                                                <p className="mb-0">
                                                                                                                    {
                                                                                                                        errors.payment
                                                                                                                    }
                                                                                                                </p>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    )}
                                                                                            </div>
                                                                                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-12">
                                                                                                <label
                                                                                                    htmlFor={`titleInput-${index}`}
                                                                                                    className="form-label"
                                                                                                >
                                                                                                    {" "}
                                                                                                    Title{" "}
                                                                                                </label>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    id={`titleInput-${index}`}
                                                                                                    value={
                                                                                                        field.title
                                                                                                    }
                                                                                                    disabled
                                                                                                    className="form-control"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </Tab>
                                                                    )
                                                                )}
                                                            </Tabs>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div>
                                                    {formik.touched
                                                        .dynamicFields &&
                                                        formik.errors
                                                            .dynamicFields && (
                                                            <div className="text-danger error ">
                                                                {errors.common && (
                                                                    <p>
                                                                        {
                                                                            errors.common
                                                                        }
                                                                    </p>
                                                                )}
                                                            </div>
                                                        )}
                                                </div> */}
                                            </div>
                                        </div>
                                        {formdata?.length > 0 && (
                                            <div className="card border-0 mt-4 mb-0">
                                                <div className="card-body p-4">
                                                    <h3>
                                                        Service Certificate
                                                        Editor
                                                    </h3>
                                                    <div className="row">
                                                        <div className="col-12 mb-0 mb-sm-3">
                                                            <CKEditorModel
                                                                data={
                                                                    formik
                                                                        .values
                                                                        .certificateTemplate
                                                                }
                                                                onChange={(
                                                                    event,
                                                                    editorData
                                                                ) =>
                                                                    formik.setFieldValue(
                                                                        "certificateTemplate",
                                                                        editorData
                                                                    )
                                                                }
                                                            />
                                                            <h6 className="mt-2">
                                                                {" "}
                                                                Note: Copy the
                                                                value from below
                                                                List and paste
                                                                between @@{" "}
                                                                <strong>
                                                                    {" "}
                                                                    value{" "}
                                                                </strong>{" "}
                                                                @@.{" "}
                                                            </h6>
                                                            {formik.errors
                                                                .certificateTemplate && (
                                                                <div className="text-danger error ">
                                                                    {
                                                                        formik
                                                                            .errors
                                                                            .certificateTemplate
                                                                    }
                                                                </div>
                                                            )}
                                                            {selectedDepartment && (
                                                                <Button
                                                                    className="my-3"
                                                                    onClick={() =>
                                                                        previewShowToggle()
                                                                    }
                                                                >
                                                                    Preview
                                                                    Service
                                                                    Template
                                                                </Button>
                                                            )}
                                                            <>
                                                                {formdata?.length >
                                                                    0 && (
                                                                    <div className="mt-5">
                                                                        {/* Search Input */}
                                                                        <InputGroup className="mb-3">
                                                                            <Input
                                                                                placeholder="Search by label"
                                                                                value={
                                                                                    searchQuery
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    setSearchQuery(
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </InputGroup>

                                                                        {/* Table */}

                                                                        <div className="row">
                                                                            {filteredData?.map(
                                                                                (
                                                                                    form,
                                                                                    index
                                                                                ) => (
                                                                                    <div className="col-md-4 col-sm-6">
                                                                                        <div
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            className="my-2 my-sm-3"
                                                                                        >
                                                                                            <label className="mb-0 fs-14 fw-bold text-black pe-2 text-break">
                                                                                                {
                                                                                                    form.label
                                                                                                }
                                                                                            </label>
                                                                                            <div className="mb-2 mt-1 fs-14 text-black d-flex align-items-center">
                                                                                                <span>
                                                                                                    {
                                                                                                        form.name
                                                                                                    }
                                                                                                </span>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="p-0 border-0 ms-2 bg-transparent lh-0"
                                                                                                    onClick={() =>
                                                                                                        copyToClipboard(
                                                                                                            form.name,
                                                                                                            index
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {copiedIndex ===
                                                                                                    index ? (
                                                                                                        <FaRegCopy color="#f99f1e" />
                                                                                                    ) : (
                                                                                                        <FaRegCopy
                                                                                                            color="gray"
                                                                                                            title="Copy"
                                                                                                        />
                                                                                                    )}
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        </div>
                                                    </div>
                                                    {/* <Button
                                                        className=" btn btn-primary "
                                                        type="submit"
                                                        disabled={loading}
                                                    >
                                                        {" "}
                                                        {loading ? (
                                                            <>
                                                                <Spinner
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    className="fs-13"
                                                                />
                                                                <span className="fs-13">
                                                                    {" "}
                                                                    Submitting...{" "}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <span className="fs-13">
                                                                {" "}
                                                                Submit{" "}
                                                            </span>
                                                        )}
                                                    </Button> */}
                                                </div>
                                            </div>
                                        )}
                                        <div className="card-footer p-4">
                                            {(createPermission ||
                                                editPermission) && (
                                                <div
                                                    className="modal-footer"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <Button
                                                        className=" btn btn-primary "
                                                        type="submit"
                                                        disabled={loading}
                                                        onClick={() =>
                                                            formik.validateForm()
                                                        }
                                                    >
                                                        {" "}
                                                        {loading ? (
                                                            <>
                                                                <Spinner
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    className="fs-13"
                                                                />
                                                                <span className="fs-13">
                                                                    {" "}
                                                                    Submitting...{" "}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <span className="fs-13">
                                                                {" "}
                                                                Submit{" "}
                                                            </span>
                                                        )}
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PreviewTemplateModel
                show={previewshow}
                setShow={setPreviewShow}
                handleToggle={previewShowToggle}
                data={formik.values}
                selectedDepartment={selectedDepartment}
            />
        </>
    );
};

export default ServiceModal;
