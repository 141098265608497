import React from "react";
import { Offcanvas } from "react-bootstrap";
import SimpleBar from "simplebar-react";

const KnowledgeBaseOffcanvas = ({ show, handleClose, knowledgeBaseId }) => {
  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      style={{ width: "80%" }}
    >
      <SimpleBar style={{ maxHeight: 'calc(100vh - 50px)', overflowY: 'auto', overflowX: 'hidden' }}>
        <Offcanvas.Header className="sticky-top bg-white" closeButton>
          <Offcanvas.Title>View Knowledge Base</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html: knowledgeBaseId?.description,
                }}
              ></span>
            </p>
          </div>
        </Offcanvas.Body>
      </SimpleBar>
    </Offcanvas>
  );
};

export default KnowledgeBaseOffcanvas;
