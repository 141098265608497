import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
} from "reactstrap";
import { toast } from "react-toastify";
import { BiSortAlt2 } from "react-icons/bi";
import { useFormik } from "formik";
import Pagination from "../../CustomComponents/Pagination";
import * as Yup from "yup";
import DepartmentModal from "./DepartmentModal";
import "../css/fileupload.css";
import Swal from "sweetalert2";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import Noimage from "../../assets/images/NoImage.jpg";
import { decrypt } from "../../utils/encryptDecrypt/encryptDecrypt";
import ScrollToTop from "../../common/ScrollToTop/ScrollToTop";
import SimpleBar from "simplebar-react";
import { Eye, UserPlus } from "feather-icons-react/build/IconComponents";
import { RefreshCcw } from "feather-icons-react";
import DepartmentUserInfo from "../../common/UserInfo/DepartmentUserInfo";
import errorImage from "../../assets/images/error.gif";
import Loader,{LoaderSpin} from "../../common/Loader/Loader";
import {
  hasCreatePermission,
  hasDeletePermission,
  hasEditPermission,
  hasViewPermission,
} from "../../common/CommonFunctions/common";
import NotFound from "../../common/NotFound/NotFound";
import useAxios from "../../utils/hook/useAxios";
const BlankData = process.env.REACT_APP_BLANK;
const Department = () => {
  const axiosInstance = useAxios()
  // table data filter search sort
  const userEncryptData = localStorage.getItem("userData");
  const userDecryptData = userEncryptData
    ? decrypt({ data: userEncryptData })
    : {};
  const userData = userDecryptData?.data;
  const userId = userData?.id;
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [orderBy, setOrderBy] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  const [isLoading, setIsloading] = useState(true);
  // add update modal
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [perPageSize, setPerPageSize] = useState(25);
  const totalPages = Math.ceil(totalCount / perPageSize);
  // upload Image
  const [selectedFile, setSelectedFile] = useState(null);

  const userPermissionsEncryptData = localStorage.getItem("userPermissions");
  const userPermissionsDecryptData = userPermissionsEncryptData
    ? decrypt({ data: userPermissionsEncryptData })
    : { data: [] };
  const UserPermissions =
    userPermissionsDecryptData &&
    userPermissionsDecryptData?.data?.find((module) => module.slug === "departments");
  const viewPermissions = UserPermissions
    ? hasViewPermission(UserPermissions)
    : false;
  const createPermission = UserPermissions
    ? hasCreatePermission(UserPermissions)
    : false;
  const editPermission = UserPermissions
    ? hasEditPermission(UserPermissions)
    : false;
  const deletePermission = UserPermissions
    ? hasDeletePermission(UserPermissions)
    : false;

  const handleImageUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    const allowedFormats = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/webp",
    ];

    const maxSize = 1024 * 1024;

    if (selectedFile.size > maxSize) {
      formik.setFieldError(
        "documentFile",
        "Please select an image file that is less than 1MB."
      );
      event.target.value = null;
      return;
    }

    if (!allowedFormats.includes(selectedFile.type)) {
      formik.setFieldError(
        "documentFile",
        "Please select a valid image file (JPEG, JPG, or PNG)."
      );
      event.target.value = null;
      return;
    }

    formik.setFieldValue("documentFile", selectedFile);
    setSelectedFile(selectedFile);
    formik.setFieldError("documentFile", "");
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setId();
    setSelectedFile(null);
    formik.resetForm();
    formik.setErrors({});
  };
  const fetchDepartmentList = async () => {
    try {
      setIsloading(true);
      const response = await axiosInstance.post(
        `serviceManagement/department/view`,
        {
          page: currentPage,
          perPage: perPageSize,
          sortOrder: sortOrder,
          orderBy: orderBy,
        }
      );

      if (response?.data) {
        const { rows, count } = response?.data?.data;
        setData(rows);
        setTotalCount(count);
        setIsloading(false);
      }
    } catch (error) {
      setIsloading(false);
      console.error(error.message);
    }
  };
  const listOfSearch = async () => {
    try {
      setIsloading(true);
      const response = await axiosInstance.post(
        `serviceManagement/department/view`,
        {
          page: currentPage,
          perPage: perPageSize,
          searchFilter: searchQuery,
          sortOrder: sortOrder,
          orderBy: orderBy,
        }
      );

      if (response?.data) {
        const { rows, count } = response?.data?.data;
        setData(rows);
        setTotalCount(count);
        setIsloading(false);
      }
    } catch (error) {
      setIsloading(false);
      console.error(error.message);
    }
  };

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      if (searchQuery) {
        listOfSearch();
      }
    }, 500);
    return () => clearTimeout(delayedSearch);
  }, [searchQuery, currentPage, perPageSize, orderBy, sortOrder]);

  useEffect(() => {
    if (!searchQuery) {
      fetchDepartmentList();
    }
  }, [searchQuery, currentPage, perPageSize, orderBy, sortOrder]);

  const handleSelectPageSize = (e) => {
    setCurrentPage(1);
    setPerPageSize(parseInt(e.target.value, 10));
  };

  const handleInputSearch = (e) => {
    setCurrentPage(1);
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (page) => {
    if (page < 1) {
      page = 1;
    } else if (page > totalPages) {
      page = totalPages;
    }
    setCurrentPage(page);

    if (page === totalPages) {
      document.querySelector(".pagination-next").classList.add("disabled");
    } else {
      document.querySelector(".pagination-next").classList.remove("disabled");
    }

    if (page === 1) {
      document.querySelector(".pagination-prev").classList.add("disabled");
    } else {
      document.querySelector(".pagination-prev").classList.remove("disabled");
    }
  };

  const resetFilters = async () => {
    setCurrentPage(1);
    setPerPageSize(25);
    setSearchQuery("");
  };

  const addDepartment = async (values) => {
    try {
      setLoading(true);
      let fileId = null;
      if (selectedFile) {
        const formData = new FormData();
        formData.append("viewDocumentName", values?.departmentName);
        formData.append("documentFile", values.documentFile);
        formData.append("userId", userId);
        formData.append("isGenerated", "0");
        formData.append("isShowInDocument", "0");
        const fileResponse = await axiosInstance.post(
          "documentService/uploading",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        fileId = fileResponse?.data?.data
          ? fileResponse?.data?.data?.[0]?.id
          : null;
      }
      const response = await axiosInstance.post(
        `serviceManagement/department/create`,
        {
          ...values,
          logo: fileId,
          documentFile: undefined,
          customerId: undefined,
        }
      );
      if (response) {
        toast.success("Department added successfully.");
        fetchDepartmentList();
        handleClose();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Something went wrong while create new department");
    }
  };

  const updateDeaprtment = async (id, values) => {
    try {
      if (id) {
        setLoading(true);
        let fileId = null;
        if (selectedFile) {
          const formData = new FormData();

          formData.append("viewDocumentName", values?.departmentName);
          formData.append("documentFile", values.documentFile);
          formData.append("userId", userId);
          formData.append("isGenerated", "0");
          formData.append("isShowInDocument", "0");
          const fileResponse = await axiosInstance.post(
            "documentService/uploading",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          fileId = fileResponse?.data?.data
            ? fileResponse?.data?.data?.[0]?.id
            : null;
        }
        const response = await axiosInstance.put(
          `serviceManagement/department/update`,
          {
            id: id,
            ...values,
            logo: fileId ? fileId : formik.values.logo,
            documentFile: undefined,
            customerId: undefined,
          }
        );
        if (response) {
          toast.success("Department updated successfully.");
          fetchDepartmentList();
          handleClose();
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error("No changes were made.");
      console.error("Something went wrong while update department");
    }
  };
  const deleteDepartment = async (deleteId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this department!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#303e4b",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const response = await axiosInstance.put(
          `serviceManagement/department/delete`,
          {
            id: deleteId,
          }
        );
        if (response) {
          toast.success(`Department deleted successfully.`);
          fetchDepartmentList();
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(`Failed to delete department.`);
        console.error(error);
      }
    }
  };

  const handleSorting = (value) => {
    setOrderBy(value);
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  };
  const validationSchema = Yup.object().shape({
    departmentName: Yup.string().required("Please enter department name"),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Please enter email"),
    url: Yup.string()
      .url("Please enter valid url eg:http://example.com")
      .required("Please enter url"),
    contactNumber: Yup.string()
      .matches(
        /^\d{10,}$/,
        "Please enter at least 10 digit. Only digits are allowed"
      )
      .required("Please enter contact number"),
    contactNumberExt: Yup.string().required("Please enter conatct number ext"),
    shortDescription: Yup.string().required("Please enter short description"),
    locationTitle: Yup.string().required("Please enter location title"),
    location: Yup.string().required("Please enter location"),
    documentFile: selectedFile
      ? Yup.mixed()
      : Yup.mixed().required("Please upload a department logo"),
    customerId: Yup.number(),
  });
  const formik = useFormik({
    initialValues: {
      departmentName: "",
      email: "",
      url: "",
      contactNumber: "",
      contactNumberExt: "",
      shortDescription: "",
      locationTitle: "",
      location: "",
      keyword: "",
      documentFile: "",
      customerId: 1,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (id) {
        updateDeaprtment(id, values);
      } else {
        addDepartment(values);
      }
    },
  });
  const updateDepartmentPrefilledData = async (data) => {
    if (data) {
      setId(data?.id);
      formik.setValues({
        ...formik.values,
        departmentName: data.departmentName || "",
        email: data.email || "",
        url: data.url || "",
        contactNumber: data.contactNumber || "",
        contactNumberExt: data.contactNumberExt || "",
        shortDescription: data.shortDescription || "",
        locationTitle: data.locationTitle || "",
        location: data.location || "",
        status: data.status || "",
        logo: data.logo || "",
        keyword: data.keyword || "",
        documentFile: data.logo || "",
        imageData: data.imageData || "",
      });
    }
    setShow(true);
  };

  document.title = "Departments | eGov Solution";

  return (
    <>

        <div id="layout-wrapper">
          <div className="main-content">
            <div className="page-content">
              <Container fluid>
                <Row>
                  <DepartmentUserInfo />
                  <Col xs={12}>
                    <div className="page-title-box header-title d-sm-flex align-items-center justify-content-between pt-lg-4 pt-3">
                      <h4 className="mb-sm-0">Departments</h4>
                      <div className="page-title-right">
                        <div className="mb-0 me-2 fs-15 text-muted current-date"></div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Card className="border-0">
                  <CardBody className="border-0">
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xxl-2 mb-3 mb-sm-0">
                        <div className="search-box d-flex align-items-center">
                          <Input
                            type="text"
                            className="form-control search bg-light border-light"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleInputSearch}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-4 col-xxl-2 ">
                        <Button
                          color="primary"
                          className="bg-light border-light  text-muted d-flex align-items-center"
                          onClick={resetFilters}
                        >
                          <RefreshCcw
                            className="text-muted me-2"
                            width="16"
                            height="16"
                          />
                          <span>Reset</span>
                        </Button>
                      </div>
                      {createPermission && (
                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-5 col-8 col-xxl-2  ms-auto text-end">
                          <Button
                            color="dark"
                            id="create-btn"
                            onClick={handleShow}
                          >
                            <UserPlus
                              className=" me-2"
                              width="20"
                              height="20"
                            />
                            Create Department
                          </Button>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
                <Card className="border-0 mb-0">
                  <CardBody className="pb-0">
                    <div className="table-responsive table-card mb-0">
                      <SimpleBar
                        style={{
                          maxHeight: "calc(100vh - 50px)",
                          overflowX: "auto",
                        }}
                      >
                        <Table
                          className="table align-middle table-nowrap mb-0 com_table"
                          id="tasksTable"
                        >
                          <thead className="sticky-top bg-white">
                            <tr>
                              <th
                                className="fw-bold cursor-pointer"
                                onClick={() => handleSorting("departmentName")}
                              >
                                Department Name{" "}
                                <span>
                                  <BiSortAlt2 />
                                </span>
                              </th>
                              <th className="fw-bold">Email</th>
                              <th className="fw-bold">URL</th>
                              <th className="fw-bold">Contact Number</th>
                              <th className="fw-bold">Location Title</th>
                              <th className="fw-bold">Location</th>
                              <th className="fw-bold">Status</th>
                              <th className="fw-bold">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoading? (
                              <tr>
                              <td colSpan="8" className="text-center">
                              <LoaderSpin /> 
                              </td>
                          </tr>
                            ): data.length === 0?(
                              <tr>
                                <td colSpan="8" className="text-center">
                                  {" "}
                                  <NotFound 
                                    heading="Department not found."
                                    message="Unfortunately, department not available at the moment." 
                                   />
                                 {" "}
                                </td>
                              </tr>
                            ):(

                              data.map((department, index) => (
                                <tr key={index}>
                                  <td className="w-xl">
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0 me-2">
                                        <img
                                          src={
                                            department?.imageData
                                              ?.documentPath || Noimage
                                          }
                                          alt=""
                                          className="avatar-xs rounded-circle"
                                        />
                                      </div>
                                      <div className="fw-semibold text-black">
                                        {department.departmentName || BlankData}
                                      </div>
                                    </div>
                                  </td>
                                  <td>{department.email || BlankData}</td>
                                  <td>{department.url || BlankData}</td>
                                  <td>{department.contactNumber || BlankData}</td>
                                  <td>{department.locationTitle || BlankData}</td>
                                  <td>{department.location || BlankData}</td>

                                  <td className="status-update  fw-bold">
                                    {" "}
                                    {department.status === "1" ? (
                                      <div className="badge badge-soft-success text-success fs-12">
                                        <i className="ri-checkbox-circle-line align-bottom"></i>{" "}
                                        Active
                                      </div>
                                    ) : (
                                      <div className="badge badge-soft-warning text-warning fs-12">
                                        <i className="ri-close-circle-line align-bottom"></i>{" "}
                                        In-Active
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <span>
                                      {viewPermissions && !editPermission && (
                                        <span
                                          title="view"
                                          className="cursor-pointer me-4"
                                          onClick={() =>
                                            updateDepartmentPrefilledData(
                                              department
                                            )
                                          }
                                        >
                                          <Eye
                                            width="16"
                                            height="16"
                                            className="text-primary "
                                          />
                                        </span>
                                      )}
                                      {editPermission && (
                                        <span
                                          title="Edit"
                                          onClick={() =>
                                            updateDepartmentPrefilledData(
                                              department
                                            )
                                          }
                                        >
                                          <FiEdit2 className="cursor-pointer me-4" />
                                        </span>
                                      )}
                                      {deletePermission && (
                                        <span
                                          title="Delete"
                                          onClick={() =>
                                            deleteDepartment(department.id)
                                          }
                                        >
                                          <RiDeleteBinLine className=" cursor-pointer" />
                                        </span>
                                      )}
                                    </span>
                                  </td>
                                </tr>
                              ))
                            )}
                           
                          </tbody>
                        </Table>
                      </SimpleBar>
                    </div>
                  </CardBody>
                  {/* Existing pagination code */}
                  <Pagination
                    totalCount={totalCount}
                    perPageSize={perPageSize}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handleSelectPageSize={handleSelectPageSize}
                    handlePageChange={handlePageChange}
                  />
                </Card>
            
              </Container>
            </div>
          </div>
          <DepartmentModal
            show={show}
            handleClose={handleClose}
            updateId={id}
            formik={formik}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            handleImageUpload={handleImageUpload}
            loading={loading}
            viewPermissions={viewPermissions}
            createPermission={createPermission}
            editPermission={editPermission}
          />
        </div>
        <ScrollToTop />
    
    </>
  );
};

export default Department;
