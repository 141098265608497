import React from "react";

const AnnouncementsSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlnssvgjs="http://svgjs.com/svgjs"
            width="512"
            height="512"
            x="0"
            y="0"
            viewBox="0 0 32 32"
            style={{
                enableBackground: "new 0 0 512 512",
                height: "20px",
                width: "20px",
            }}
            xmlSpace="preserve"
            className="me-2"
        >
            {" "}
            <g>
                {" "}
                <g
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_2"
                    data-name="Layer 2"
                >
                    {" "}
                    <path
                        d="m20.683 5.518a3 3 0 0 0 -2.8-.3l-7.076 2.83h-5.807a3 3 0 0 0 -3 3v6a3 3 0 0 0 2 2.816v5.184a3 3 0 1 0 6 0v-5h.807l7.079 2.831a3 3 0 0 0 4.114-2.785v-12.094a3 3 0 0 0 -1.317-2.482zm-16.683 5.53a1 1 0 0 1 1-1h5v8h-5a1 1 0 0 1 -1-1zm4 14a1 1 0 1 1 -2 0v-5h2zm12-4.954a1 1 0 0 1 -1.372.928l-6.628-2.651v-8.646l6.628-2.651a1 1 0 0 1 1.372.926z"
                        fill="#ffffff"
                        data-original="#ffffff"
                    ></path>{" "}
                    <path
                        d="m29 13.048h-3a1 1 0 0 0 0 2h3a1 1 0 0 0 0-2z"
                        fill="#ffffff"
                        data-original="#ffffff"
                    ></path>{" "}
                    <path
                        d="m26 8.048a.991.991 0 0 0 .446-.106l2-1a1 1 0 1 0 -.894-1.789l-2 1a1 1 0 0 0 .448 1.895z"
                        fill="#ffffff"
                        data-original="#ffffff"
                    ></path>{" "}
                    <path
                        d="m28.447 21.153-2-1a1 1 0 1 0 -.894 1.789l2 1a.991.991 0 0 0 .446.106 1 1 0 0 0 .448-1.895z"
                        fill="#ffffff"
                        data-original="#ffffff"
                    ></path>{" "}
                </g>{" "}
            </g>{" "}
        </svg>
    );
};

export default AnnouncementsSvg;
